import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import './RealizationMap.scss';


const getListCieisForMap = (cities) => {
    let result = [];

    cities.map((city) => {
        if (result.length === 0) {
            result = [city]
        } else {
            const citiesNames = result.map(element => element.city);

            if (!citiesNames.includes(city.city)) {
                result = [
                    ...result,
                    city
                ];

            } else {
                let label = [];
                const modifiedList = result.map((element) => {
                    if (element.city === city.city) {
                        if (Array.isArray(element.label)) {
                            label = [...element.label, city.label]
                        } else {
                            label = [element.label, city.label];
                        }
                        return {
                            ...element,
                            label
                        }
                    } else {
                        return element
                    }
                });
                result = [
                    ...modifiedList
                ];
            }
        }

    });

    return result;
}


export default function RealizationMap({ cities, onRealizationClikc }) {
    const listForMap = getListCieisForMap(cities);

    const renderLabel = (label, city, url) => {
        const path = url.substring(url.lastIndexOf('/') + 1);
        url = `/realizacje/${path}`;

        return (
            <>
                <p className='header-pin'>Lista naszych prac w <strong>{city}</strong></p>
                <ul>
                    {label.map((str, index) => {
                        return (
                            <li key={index}>
                                <a href={url}>{str}</a>
                            </li>
                        )
                    })}
                </ul>
            </>
        );
    }

    return (
        <>
            <div className='RealisationMap'>
                <div id="mapid"></div>
                <MapContainer center={[49.479240735510295, 20.023137224494324]} zoom={13} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {listForMap.map(({ latitudeLongitude, label, city, url }, index) => {
                        const isLabelArray = Array.isArray(label);
                        const path = url.substring(url.lastIndexOf('/') + 1);
                        url = `/realizacje/${path}`;

                        return (
                            <Marker key={index} position={latitudeLongitude}>
                                <Popup>
                                    {isLabelArray ?
                                        renderLabel(label, city, url)
                                        :
                                        <ul>
                                            <li>
                                                <a href={url}>{label}</a>
                                            </li>
                                        </ul>
                                    }
                                </Popup>
                            </Marker>
                        )
                    })}
                </MapContainer>
            </div>
        </>
    );
}