import React, { Component } from 'react';

import szwedzkaDotacja from "../../assets/szwedzkaDotacja.jpg";
import szwedzkaPromocjaRegulamin from "../../assets/szwedzkaPromocjaRegulamin.pdf";
import szwedzkaPromocjaUlotka from "../../assets/szwedzkaPromocjaUlotka.pdf";
import "./SzwedzkaDotacja.scss";

export default class SzwedzkaDotacja extends Component {
    render() {
        return (
            <div className="SzwedzkaDotacja">
                <h4>SZWEDZKA PROMOCJA – CASHBACK</h4>
                <a href='https://szwedzkapromocja.pl/' target='_blank' rel="noopener noreferrer">
                    <img src={szwedzkaDotacja} alt='Szwedzka Dotacka'></img>
                </a>
                <p>„Szwedzka Promocja – Cashback” trwa <strong>od 16.08.2023 r. do 30.11.2023 r. </strong> lub do wyczerpania puli pomp ciepła.

                    Promocja skierowana jest do końcowych Użytkowników, którzy w okresie trwania promocji dokonają zakupu powietrznej pompy ciepła NIBE, zachowają oryginał dowodu zakupu i zarejestrują się na stronie www.szwedzkapromocja.pl.</p>

                <h3>Produktami objętymi promocją jest łącznie 2500 szt. powietrznych pomp ciepła NIBE:</h3>
                <ul className='Page-list'>
                    <li>
                        typu monoblok: NIBE F2040, NIBE F2120, NIBE S2125
                    </li>
                    <li>
                        typu split: NIBE SPILT AMS.
                    </li>
                </ul>
                <strong>W ramach promocji NIBE-BIAWAR Sp. z o.o. oferuje częściowy zwrot (cashback) kwoty zakupu pompy ciepła NIBE, w postaci Premii pieniężnej w wysokości 2000 zł.</strong>
                <p>O wzięciu udziału w promocji decyduje kolejność zgłoszeń, tj. odesłania kompletnego formularza rejestracyjnego.
                    Szczegółowe informacje dotyczące promocji wraz z regulaminem i formularzem rejestracyjnym, dostępne są na <a href='https://szwedzkapromocja.pl/' target='_blank' rel="noopener noreferrer">www.szwedzkapromocja.pl</a></p>
                <a href={szwedzkaPromocjaRegulamin} target='_blank' rel="noopener noreferrer" className='regulamin'><span>regulamin promocji</span></a>
                <a href={szwedzkaPromocjaUlotka} target='_blank' rel="noopener noreferrer" className='regulamin'><span>ulotka promocji</span></a>
            </div>
        );
    }
}