import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import "./HeatPumpsPageRealisations.scss";

export default class HeatPumpsPageRealisations extends Component {
    render() {
        return (
            <div className="HeatPumpsPageRealisations">
                <h4>nasze realizacje</h4>
                <p>Zapraszamy do zapoznania się z realizacjami Naszej Firmy i do skorzystania z Naszych usług.</p>

                <NavLink className='HeatPumpsPageRealisations-realisation-link' to='/realizacje'>zobacz galerię realizacji</NavLink>
            </div>
        );
    }
}