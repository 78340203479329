import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { CookiesProvider } from "react-cookie";
import { withCookies, Cookies } from "react-cookie";
import { withRouter } from 'react-router'

import 'normalize.css';

import './App.scss';

import TopBar from "./TopBar/TopBar";
import BottomBar from "./BottomBar/BottomBar";

export function App(props) {
  const [savedCookie, setCookie] = useState(null);
  const cookies = new Cookies();

  useEffect(() => {
    const cookie = cookies.get('cookies_accepted');

    if (cookie) {
      setCookie(cookie)
    }
  }, []);

  useEffect(() => {
    ReactGA.initialize('UA-190732540-1');
    ReactGA.pageview(window.location.pathname);

    props.history.listen((location, action) => {
      ReactGA.pageview(location.pathname);
    });


  }, []);

  const handleCookie = () => {
    cookies.set("cookies_accepted", "yes", {
      path: "/"
    });

    setCookie('cookies_accepted')
  }

  return (
    <CookiesProvider>
      <div className="App">
        <TopBar />
        <BottomBar />
      </div>
      {!savedCookie &&
        <div className='Cookie-banner'>
          <div>
            <h3>Ta strona używa plików cookies</h3>
            <p>Używamy plików cookies w celu mierzenia popularnosci naszego serwisu. Kontynuując przeglądanie tej strony oznacza, iż użytkownik akceptuje stosowanie plików cookies i naszą <a href='/polityka_prywatnosci'>Politykę Prywatności.</a></p>
          </div>
          <div className='Cookie-banner-button'>
            <button className='acion-button' onClick={handleCookie}>Akceptuję</button>
          </div>
        </div>
      }
    </CookiesProvider>
  );
}

export default withCookies(withRouter(App));
