import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import HomeGallery from "../HomeGallery/HomeGallery";
import "./HomePage.scss";

import czystePowietrzeBaner1 from "./assets/czyste_powietrze_baner_4.jpg";
import pompy1 from "./assets/pompy1.jpg";
import pompy3 from "./assets/pompy3.jpg";
import pompy4 from "./assets/pompy4.jpg";
import szwedzkaPromocja from "./assets/szwedzka promocja.jpg";

export default class HomePage extends Component {
    render() {
        return (
            <div className="HomePage">
                <Carousel className="HomePage-carousel"
                    showArrows={true}
                    dynamicHeight
                    autoPlay
                    infiniteLoop
                    interval={3000}
                    transitionTime={150}
                >
                    <div>
                        <a href='/dotacje_1/szwedzka_dotacja'>
                            <img src={szwedzkaPromocja} alt='szwedzka promocja' />
                        </a>
                    </div>
                    <div>
                        <a href='/dotacje_1/program_czyste_powietrze'>
                            <img src={czystePowietrzeBaner1} alt='czyste powietrze' />
                        </a>

                    </div>
                    <div>
                        <img src={pompy1} alt='pompy' />
                        <a href='/instalacje'>
                            <p className="legend">Zaufaj doświadczeniu. Profesjonalny montaż i doradztwo</p>
                        </a>
                    </div>
                    <div>
                        <img src={pompy3} alt='pompy' />
                        <a href="/pompy_ciepla">
                            <p className="legend">Pompy ciepła. Źródło taniej i czystej energii</p>
                        </a>
                    </div>
                    <div>
                        <img src={pompy4} alt='pompy' />
                        <a href='/realizacje'>
                            <p className="legend">Kompleksowe realizacje. Projekt - montaż - serwis</p>
                        </a>
                    </div>
                </Carousel>
                <HomeGallery />
            </div>
        );
    }
}
