import React, { Component, Fragment } from 'react';

import schemat from "../../assets/schemat.jpg";
import schemat2 from "../../assets/schemat2.jpg";

export default class HeatPumpsPageTheoryPage extends Component {
    render () {
        return (
            <Fragment>
                <h4>troche teorii</h4>
                <p>Patrząc na otaczającą nas rzeczywistość, możemy stwierdzić, że z pompami ciepła mamy do czynienia od wielu lat, ale teraz zaczynamy wykorzystywać je do ogrzewania i chłodzenia naszych mieszkań i domów.</p>
                <p>Jako klasyczny przykład pracy pompy ciepła, możemy wskazać urządzenie które znajduje się w każdym domu -  lodówkę. Lodówka to także pompa ciepła, tyle że odwrócona. W lodówce tworzymy chłód, który wykorzystujemy do przechowywania żywności, a ciepło jest dla nas produktem odpadowym.
                    Każdy z nas, może sprawdzić, ile ciepła tworzy lodówka, jeżeli włożymy do niej dużo ciepłych produktów żywnościowych. Po załadowaniu lodówki dużą ilością przyniesionych do domu zakupów możemy po jakimś czasie sprawdzić temperaturę skraplacza, czyli tego urządzenia które znajduje się z tył lodówki. Jeżeli lodówka pracuje bardzo intensywnie, to skraplacz może osiągnąć temperaturę nawet około 70ºC!
                </p>
                <h6>Jak to sie dzieje?</h6>
                <p>Krótką definicję przytacza nam Wikipedia:</p>
                <p>Pompa ciepła, jest maszyną cieplną, wymuszającą przepływ ciepła, z obszaru o niższej temperaturze do obszaru o temperaturze wyższej. Proces ten, przebiega wbrew naturalnemu kierunkowi przepływu ciepła, i zachodzi dzięki dostarczonej z zewnątrz energii mechanicznej,  potrzebnej do pracy sprężarki.</p>
                <p>W celu zrozumienia tej definicji i zachodzącego procesu zerknijmy na poniższy schemat i wyjaśnijmy kilka podstawowych pojęć:</p>
                <img src={schemat} alt='trochę teorii'></img>
                <p>Ideą działania pompy ciepła jest transport i przekazywanie energii (ok 75% - większa strzałka na schemacie) z dolnego źródła (grunt, powietrze, woda) do instalacji grzewczej. Obieg ten umożliwiają nam ( w bardzo dużym uproszeniu) takie elementy jak:</p>
                <ul className="Page-list">
                    <li>sprężarka – serce pompy ciepła</li>
                    <li>zawór rozprężny – umożliwia realizację całego cyklu</li>
                    <li>parownik – to tutaj pobieramy energię z otoczenia</li>
                    <li>skraplacz – tutaj oddajemy ją do instalacji grzewczej</li>
                    <li>czynnik chłodniczy – umożliwia transport energii.</li>
                </ul>
                <p>Niezbędna jest tu również energia (ok 25% - mniejsza strzałka na schemacie) potrzebna do pracy sprężarki.</p>
                <p>W zachodzącym procesie energia z dolnego źródła poprzez parownik przekazywana jest do czynnika chłodniczego.
                    Sprężarka w kolejnym etapie podnosi ciśnienie a co za tym idzie temperaturę czynnika, który oddaje zgromadzone ciepło do instalacji grzewczej poprzez skraplacz.
                    Następnie w zaworze rozprężnym następuje spadek ciśnienie w celu kontynacji procesu w parowniku.
                </p>
                <p>Wyjaśnijmy jeszcze, trochę bardziej szczegółowo dwa ważne pojęcia:</p>
                <p><b>Dolne źródło</b> – to miejsce skąd pompa ciepła będzie pobierała energie cieplną potrzebną do odparowania czynnika chłodniczego w parowniku. W przypadku pomp powietrznych będzie to otaczające nas powietrze a w przypadku pomp gruntowych, grunt, w którym podgrzewamy glikol, przy pomocy kolektorów poziomych lub pionowych lub woda.</p>
                <p><b>Górne źródło</b>, to miejsce gdzie pompa ciepła będzie oddawała wytworzone ciepło, czyli instalacja grzewcza – np. ogrzewanie podłogowe, klimakonwektory lub zasobnik z ciepła wodą użytkową</p>
                <img src={schemat2} alt='trochę teorii'></img>
                <h6>Skąd się bierze oszczędność?</h6>
                <p>Tutaj pojawia się tajemniczy skrót COP (ang. Ceofficient Of Performance)  – czyli współczynnik efektywności. Jest to stosunek ilości energii jaką dostarczyliśmy do układu ogrzewania – górnego źródła do ilości energii (najczęściej elektrycznej) jaka była niezbędna do pracy pompy ciepła – głównie sprężarki.</p>
                <p>Mówiąc prościej, jeśli wartość COP wynosi np.: 3 oznacza to, że jeżeli potrzebowaliśmy do ogrzania naszego domu 100 jednostek energii, to naszym kosztem będzie tylko cena za ok 33 jednostki.</p>
                <p>Współczynnik ten najczęściej przyjmuje wartości pomiędzy 2,7 a 6.</p>
                <p>Oczywiście im większa wartość współczynnika tym korzystniej dla nas.</p>
                <p>Wielkość współczynnika zależy od różnicy temperatur pomiędzy dolnym, a górnym źródłem ciepła. Im mniejsza różnica temperatury  tym mniejszą pracę musi wykonać sprężarka a co za tym idzie zużyje mniej energii i efektywniej pracuje pompa ciepła.</p>
                <p>Niezwykle zatem istotne jest optymalne zaprojektowanie instalacji.
                    Dlatego też, doskonale współpracują z pompą ciepła systemy grzewcze z niską temperaturą pracy jak np. ogrzewanie podłogowe, ścienne, sufitowe lub grzejnikowe niskotemperaturowe – klimakonwektory.
                </p>
            </Fragment>
        );
    }
} 