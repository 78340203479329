import React, { Component, Fragment } from 'react';
import { Route, Switch, NavLink } from "react-router-dom";

import Page from '../Page/Page';

import './FittingsPage.scss';
import FittingsPageMainPage from './components/FittingsPageMainPage/FittingsPageMainPage';
import FittingsFloorHeating from "./components/FittingsFloorHeating/FittingsFloorHeating";
import FittingsCeilingHeating from "./components/FittingsCeilingHeating/FittingsCeilingHeating";
import FittingsWallHeating from "./components/FittingsWallHeating/FittingsWallHeating";
import FittingsFanCoilUnits from './components/FittingsFanCoilUnits/FittingsFanCoilUnits';
import NotFoundPage from '../PageNotFound/PageNotFound';

export default class FittingsPage extends Component {
    handleActiveLink = (match, location) => {
        if (!match) {
            return false;
        }
        return true;
    }

    leftPanel() {
        return (
            <Fragment>
                <h4>menu</h4>
                <ul className='FittingsPage-links Page-list'>
                    <li><NavLink to="/instalacje/ogrzewanie_podlogowe" isActive={this.handleActiveLink}>ogrzewanie podłogowe</NavLink></li>
                    <li><NavLink to="/instalacje/ogrzewanie_sufitowe" isActive={this.handleActiveLink}>ogrzewanie sufitowe</NavLink></li>
                    <li><NavLink to="/instalacje/ogrzewanie_scienne" isActive={this.handleActiveLink}>ogrzewanie ścienne</NavLink></li>
                    <li><NavLink to="/instalacje/klimakonwektory" isActive={this.handleActiveLink}>klimakonwektory</NavLink></li>
                </ul>
            </Fragment>
        );
    }

    rightPanel() {
        return (
            <Switch>
                <Route exact path="/instalacje" component={FittingsPageMainPage} />
                <Route exact path="/instalacje/ogrzewanie_podlogowe" component={FittingsFloorHeating} />
                <Route exact path="/instalacje/ogrzewanie_sufitowe" component={FittingsCeilingHeating} />
                <Route exact path="/instalacje/ogrzewanie_scienne" component={FittingsWallHeating} />
                <Route exact path="/instalacje/klimakonwektory" component={FittingsFanCoilUnits} />
                <Route path='*' component={NotFoundPage} />
            </Switch>
        );
    }

    render() {
        return (
            <div className='FittingsPage'>
                <Page
                    className='FittingsPage-Page'
                    childLeftPanel={this.leftPanel()}
                    childRightPanel={this.rightPanel()}
                />
            </div>
        );
    }
}
