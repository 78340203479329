import React, { Component } from 'react';
import { Route, Switch, NavLink } from "react-router-dom";

import HeatPumpsPageMain from './components/HeatPumpsPageMain/HeatPumpsPageMain';
import HeatPumpsPageTheoryPage from './components/HeatPumpsPageTheoryPage/HeatPumpsPageTheoryPage';
import HeatPumpsPageAspectsPage from "./components/HeatPumpsPageAspectsPage/HeatPumpsPageAspectsPage";
import HeatPumpsPageTypesPage from "./components/HeatPumpsPageTypesPage/HeatPumpsPageTypesPage";
import HeatPumpsPageGround from "./components/HeatPumpsPageGround/HeatPumpsPageGround";
import HeatPumpsPageAir from "./components/HeatPumpsPageAir/HeatPumpsPageAir";
import HeatPumpsPageCWU from "./components/HeatPumpsPageCWU/HeatPumpsPageCWU";
import HeatPumpsPageRealisations from "./components/HeatPumpsPageRealisations/HeatPumpsPageRealisations";
import NotFoundPage from '../PageNotFound/PageNotFound';

import Page from '../Page/Page';
import './HeatPumpsPage.scss';

export default class HeatPumpsPage extends Component {
    handleActiveLink = (match, location) => {
        if (!match) {
            return false;
        }
        return true;
    }

    leftPanel() {
        return (
            <div>
                <h4>menu</h4>
                <ul className='HeatPumpsPage-links Page-list'>
                    <li><NavLink to="/pompy_ciepla/troche_teorii" isActive={this.handleActiveLink}>troche teorii</NavLink></li>
                    <li><NavLink to="/pompy_ciepla/dlaczego_pompa_ciepla" isActive={this.handleActiveLink}>dlaczego pompa ciepła</NavLink></li>
                    <li><NavLink to="/pompy_ciepla/rodzaje_pomp_ciepla" isActive={this.handleActiveLink}>rodzaje pomp ciepła</NavLink></li>
                    <li><NavLink to='/pompy_ciepla/gruntowe' isActive={this.handleActiveLink}>gruntowe</NavLink></li>
                    <li><NavLink to='/pompy_ciepla/powietrzne' isActive={this.handleActiveLink}>powietrzne</NavLink></li>
                    <li><NavLink to='/pompy_ciepla/do_cwu' isActive={this.handleActiveLink}>do c.w.u</NavLink></li>
                    <li><NavLink to='/pompy_ciepla/nasze_realizacje' isActive={this.handleActiveLink}>nasze realizacje</NavLink></li>
                </ul>
            </div>
        );
    }

    rightPanel() {
        return (
            <Switch>
                <Route exact path="/pompy_ciepla" component={HeatPumpsPageMain} />
                <Route exact path="/pompy_ciepla/troche_teorii" component={HeatPumpsPageTheoryPage} />
                <Route exact path="/pompy_ciepla/dlaczego_pompa_ciepla" component={HeatPumpsPageAspectsPage} />
                <Route exact path="/pompy_ciepla/rodzaje_pomp_ciepla" component={HeatPumpsPageTypesPage} />
                <Route exact path="/pompy_ciepla/gruntowe" component={HeatPumpsPageGround} />
                <Route exact path="/pompy_ciepla/powietrzne" component={HeatPumpsPageAir} />
                <Route exact path="/pompy_ciepla/do_cwu" component={HeatPumpsPageCWU} />
                <Route exact path="/pompy_ciepla/nasze_realizacje" component={HeatPumpsPageRealisations} />
                <Route exactpath='*' component={NotFoundPage} />
            </Switch>
        );
    }

    render() {
        return (
            <div className='HeatPumpsPage'>
                <Page
                    className='HeatPumpsPage-Page'
                    childLeftPanel={this.leftPanel()}
                    childRightPanel={this.rightPanel()}
                />
            </div>
        );
    }
}