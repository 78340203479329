import React, { Component } from 'react';

import smok from "../../assets/smok.jpg";
import paliwa from "../../assets/paliwa.jpg";
import pellet_multifuel from "../../assets/pellet_multifuel.jpg";

import "./BiomassCollectors.scss";

export default class BiomassCollectors extends Component {
    render () {
        return (
            <div className="BiomassCollectors">
                <h4>kotły na biomasę</h4>
                <p>Spalanie biomasy cieszy się coraz większą popularnością. Jest to wynikiem coraz większej świadomości użykowników, dostępności samego surowca jak i urządzeń do jego spalania. Ważny jest tu również aspekt ekonomiczny.</p>
                <p>Biomasa służąca jako paliwo do kotłów grzewczych, pozyskiwana jest z różnych źródeł.Najbardziej popularne formy biomasy jako paliwa to przetworzone odpady pochodzenia drzewnego takie jak brykiet czy pellet oraz nieprzetworzone takie jak zrębki, wióry czy trociny. Znakomicie również sprawdzają się odpady pochodzenia roślinnego takie jak pestki, łuski czy też skorupy.</p>
                <p><u>Przykładowe paliwa do kotłów na biomasę:</u></p>
                <img src={paliwa} alt='paliwa'></img>
                <p>W nowoczesnych kołach, o wysokiej sprawności, służących do spalania biomasy cały system spalania i podawania paliwa jest praktycznie zautomatyzowany. Jest to zatem znakomite rozwiązanie dla gospodarstw domowych szukających ekologicznego i automatycznego źródła ciepła. Jak i rozwiązanie dla zakładów produkujących odpady drzewne lub roślinne, które szukają taniego źródła energii do ogrzewania budynków produkcyjnych czy też suszarni.</p>
                <img src={pellet_multifuel} alt='pellet_multifuel'></img>
                <p>Realizując wspólne inwestycje ściśle współpracujemy z Firmami Ekogreń z Pszczyny oraz Moderator z Hajnówki, których urządzenia z powodzeniem pracują od wielu lat na różnego typu instalacjach o mocach ok 15kW do ponad 200kW.
                </p>
                <img src={smok} alt='smok'></img>
                <p>Do zapoznania się pełną ofertą produktów zapraszamy na strony producentów:</p>
                <p><span>moderator</span></p>
                <p><a href="http://ekogren.pl/produkty" target='_blank' rel="noopener noreferrer">ekogreń</a></p>
                <p className="bottom-center">
                    <span>zamierzasz ogrzewać biomasą</span>
                </p>
                <p className="bottom-center">
                    <span>dobierzemy i zamontujemy twój kocioł</span>
                </p>
            </div>
        );
    }
} 