export const linksList = [
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_oczyszczalnia',
    label: 'CZORSZTYN OCZYSZCZALANIA NIBE 1145 17kW',
    city: 'CZORSZTYN',
    latitudeLongitude: [49.47682400129109, 20.031286509562175]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_split_1',
    label: 'NOWY TARG NIBE SPLIT 8KW',
    city: 'NOWY TARG',
    latitudeLongitude: [49.47682400129109, 20.031286509562175]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_split_2',
    label: 'NOWY TARG NIBE SPLIT 12KW',
    city: 'NOWY TARG',
    latitudeLongitude: [49.47682400129109, 20.031286509562175]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/niedzica',
    label: 'NIEDZICA NIBE F2300 20KW',
    city: 'NIEDZICA',
    latitudeLongitude: [49.40925992131461, 20.301397411036497]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/jaworki',
    label: 'JAWORKI KOŚCIÓŁ NIBE F1330 60KW',
    city: 'JAWORKI',
    latitudeLongitude: [49.41082074658019, 20.552040445640557]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/zaskale_nibe_split',
    label: 'ZASKALE NIBE SPLIT 12kW',
    city: 'ZASKALE',
    latitudeLongitude: [49.44593339780778, 19.995076037706966]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/lapsze_bank',
    label: 'ŁAPSZE BANK NIBE 1145 17kW',
    city: 'ŁAPSZE',
    latitudeLongitude: [49.398599953306416, 20.244183817259863]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nowy_targ_nibe_f2300',
    label: 'NOWY TARG NIBE F2300 20KW',
    city: 'NOWY TARG',
    latitudeLongitude: [49.47682400129109, 20.031286509562175]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/atlantic_extensa',
    label: 'SIDZINA ATLANTIC EXTENSA 10KW',
    city: 'SIDZINA',
    latitudeLongitude: [49.59110234968065, 19.710580299142208]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/dimplex_oczyszczalnia',
    label: 'PAWŁÓW OCZYSZCZALNIA DIMPLEX SI 22TU 22KW',
    city: 'PAWŁÓW',
    latitudeLongitude: [50.291469882377015, 20.890195161401863]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/dimplex_zaskale',
    label: 'ZASKALE DIMPLEX LA20 20KW',
    city: 'ZASKALE',
    latitudeLongitude: [49.44593339780778, 19.995076037706966]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_2040_16kw',
    label: 'SUCHA BESKIDZKA NIBE F2040 16kW',
    city: 'SUCHA BESKIDZKA',
    latitudeLongitude: [49.741424260338086, 19.58650108821227]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_1155_inwerter',
    label: 'PORĘBA WIELKA NIBE F1155 16KW',
    city: 'PORĘBA WIELKA',
    latitudeLongitude: [49.60869719448001, 20.06100533682019]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_f1145_15kw',
    label: 'CHRZANÓW NIBE 1145 15kW',
    city: 'CHRZANÓW',
    latitudeLongitude: [50.134167683355095, 19.400240235001554]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/atlantic_excelia_tri_11kw',
    label: 'NOWY TARG ATLANTIC EXCELIA 11kW',
    city: 'NOWY TARG',
    latitudeLongitude: [49.47682400129109, 20.031286509562175]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_split_tymbark',
    label: 'TYMBARK NIBE SPLIT 12kW',
    city: 'TYMBARK',
    latitudeLongitude: [49.73277415427221, 20.319732897938966]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/zubadan_11kw_dbno',
    label: 'DĘBNO MITSUBISHI ZUBADAN 11kW',
    city: 'DĘBNO',
    latitudeLongitude: [52.738997774822714, 14.697999207215215]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/maniowy_split_12kw',
    label: 'MANIOWY NIBE SPLIT 12kW',
    city: 'MANIOWY',
    latitudeLongitude: [49.46030473269034, 20.266083804320964]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/zubandan_11kw_czgra',
    label: 'CZARNA GÓRA MITSUBISHI ZUBADAN 11kw',
    city: 'CZARNA GÓRA',
    latitudeLongitude: [49.377197748280516, 20.130226399625954]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_1145_8kw_toporzysko',
    label: 'TOPORZYSKO NIBE 1145 8kW',
    city: 'TOPORZYSKO',
    latitudeLongitude: [49.6250440528002, 19.79837150699373]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/dimplex_la_40kw_czorsztyn',
    label: 'CZORSZTYN DIMPLEX LA 40kW',
    city: 'CZORSZTYN',
    latitudeLongitude: [49.43813214412042, 20.32723765304473]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/dimplex_la_40kw_maniowy',
    label: 'MANIOWY DIMPLEX LA 40kW',
    city: 'MANIOWY',
    latitudeLongitude: [49.46030473269034, 20.266083804320964]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_2120_12kw_ludmierz',
    label: 'LUDŹMIERZ NIBE 2120 12kw',
    city: 'LUDŹMIERZ',
    latitudeLongitude: [49.46572052883846, 19.980751049817254]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/ochotnica_nibe_1245_8kw',
    label: 'OCHOTNICA NIBE 1245 8kW',
    city: 'OCHOTNICA',
    latitudeLongitude: [49.534028472245474, 20.31666697511879]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_1155_16kw_nowy_scz',
    label: 'NOWY SĄCZ NIBE 1155 16kW',
    city: 'NOWY SĄCZ',
    latitudeLongitude: [49.60817642540375, 20.713669815344197]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_2040_12kw_dursztyn',
    label: 'DURSZTYN NIBE 2040 12kW',
    city: 'DURSZTYN',
    latitudeLongitude: [49.41542710627377, 20.189419194951796]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_2040_12kw_vvm_320_nowy_targ',
    label: 'NOWY TARG NIBE 2040 12kW+VVM 320',
    city: 'NOWY TARG',
    latitudeLongitude: [49.47682400129109, 20.031286509562175]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/dimplex_la_tbs_28kw_czorsztyn',
    label: 'CZORSZTYN DIMPLEX LA TBS 28kW',
    city: 'CZORSZTYN',
    latitudeLongitude: [49.43813214412042, 20.32723765304473]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_2120_20kw_biaka_tatrzaska',
    label: 'BIAŁKA TATRZAŃSKA NIBE 2120 20kW',
    city: 'BIAŁKA TATRZAŃSKA',
    latitudeLongitude: [49.39301153042643, 20.104215144634797]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_2040_12kw_sucha_beskidzka',
    label: 'SUCHA BESKIDZKA NIBE 2040 12kW',
    city: 'SUCHA BESKIDZKA',
    latitudeLongitude: [49.741424260338086, 19.58650108821227]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/dimplex_si_130tur_stary_scz_biurowiec',
    label: 'STARY SĄCZ BIUROWIEC DIMPLEX SI 130TUR',
    city: 'STARY SĄCZ',
    latitudeLongitude: [49.559068101860646, 20.634039611744882]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/dimplex_si_18tu_szaflary',
    label: 'SZAFLARY DIMPLEX SI 18TU 18KW',
    city: 'SZAFLARY',
    latitudeLongitude: [49.428370399153096, 20.025841726922657]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_2120-20kw_nowy_targ',
    label: 'NOWY TARG NIBE 2120 20kW',
    city: 'NOWY TARG',
    latitudeLongitude: [49.47682400129109, 20.031286509562175]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_2040_12kw_odrow',
    label: 'ODROWĄŻ NIBE 2040 12kW',
    city: 'ODROWĄŻ',
    latitudeLongitude: [49.5019080883903, 19.855298912413954]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_1126_-_8kw_zakopane',
    label: 'ZAKOPANE NIBE 1126 8kW',
    city: 'ZAKOPANE',
    latitudeLongitude: [49.291323009460086, 19.974560867011206]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_2120_-_20kw_szczawnica',
    label: 'SZCZAWICA NIBE 2120 20KW',
    city: 'SZCZAWICA',
    latitudeLongitude: [49.42495097529479, 20.491510823729474]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/nibe_2040_-_12kw_nowy_targ',
    label: 'NOWY TARG NIBE 2040 12kW',
    city: 'NOWY TARG',
    latitudeLongitude: [49.47682400129109, 20.031286509562175]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/gdw_nibe_2120_16kw',
    label: 'GDÓW NIBE 2120 16KW',
    city: 'GDÓW',
    latitudeLongitude: [49.90868100754496, 20.19851296753304]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/rdzawka_nibe_2040_12kw',
    label: 'RDZAWKA NIBE 2040 12KW',
    city: 'RDZAWKA',
    latitudeLongitude: [49.5680545250717, 19.9609251548437]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/kocielisko_apartamenty_2xnibe_2120_20kw',
    label: 'KOŚCIELISKO APARTAMENTY 2xNIBE 2120 20KW',
    city: 'KOŚCIELISKO',
    latitudeLongitude: [49.29139814203952, 19.889380882256773]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/maniowy_nibe_ams10_12kw',
    label: 'MANIOWY NIBE AMS10 12kW',
    city: 'MANIOWY',
    latitudeLongitude: [49.46030473269034, 20.266083804320964]
  },
  {
    url: '//pompyciepla-podhale.com.pl/realizacje/zubrzyca_grna_nibe_1145_12kw',
    label: 'ZUBRZYCA GÓRNA NIBE 1145 12kW',
    city: 'ZUBRZYCA GÓRNA',
    latitudeLongitude: [49.56510344054358, 19.64902241660202]
  }
  //
  // {
  //   url: '//pompyciepla-podhale.com.pl/realizacje/zubrzyca_grna_nibe_1145_12kw',
  //   label: 'testowa labelka',
  //   city: 'ZUBRZYCA GÓRNA',
  //   latitudeLongitude: [49.56510344054358, 19.64902241660202]
  // },
  // {
  //   url: '//pompyciepla-podhale.com.pl/realizacje/zubrzyca_grna_nibe_1145_12kw',
  //   label: 'another label',
  //   city: 'ZUBRZYCA GÓRNA',
  //   latitudeLongitude: [49.56510344054358, 19.64902241660202]
  // }
]