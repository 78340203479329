import React, { Component, Fragment } from 'react';

import KnowledgeCenter from "../../../KnowledgeCenter/KnowledgeCenter";


export default class HeatPumpsPageMain extends Component {
    render () {
        return (
            <Fragment>
                <KnowledgeCenter />
            </Fragment>
        );
    }
}