import React, { Component, Fragment } from 'react';
import { Route, Switch, NavLink } from "react-router-dom";

import Page from "../Page/Page";

import EnergySourcesMainPage from "./components/EnergySourcesMainPage/EnergySourcesMainPage";
import Photovoltaics from "./components/Photovoltaics/Photovoltaics";
import SolarPanels from "./components/SolarPanels/SolarPanels";
import BiomassCollectors from "./components/BiomassCollectors/BiomassCollectors";
import Regeneration from "./components/Regeneration/Regeneration";
import OilGasBoilers from "./components/OilGasBoilers/OilGasBoilers";
import PageNotFound from '../PageNotFound/PageNotFound';

import "./EnergySourcesPage.scss";

export default class EnergySourcesPage extends Component {
    handleActiveLink = (match, location) => {
        if (!match) {
            return false;
        }
        return true;
    }

    leftPanel() {
        return (
            <Fragment>
                <h4>menu</h4>
                <ul className='EnergySourcesPage-links Page-list'>
                    <li><NavLink to="/zrodla_energii/fotowoltaika" isActive={this.handleActiveLink}>fotowoltaika</NavLink></li>
                    <li><NavLink to="/zrodla_energii/kolektory_sloneczne" isActive={this.handleActiveLink}>kolektory słoneczne</NavLink></li>
                    <li><NavLink to="/zrodla_energii/kotly_na_biomase" isActive={this.handleActiveLink}>kotły na biomase</NavLink></li>
                    <li><NavLink to="/zrodla_energii/rekuperacja" isActive={this.handleActiveLink}>rekuperacja</NavLink></li>
                    <li><NavLink to="/zrodla_energii/kotly_gazowe_olejowe" isActive={this.handleActiveLink}>kotły gazowe/olejowe</NavLink></li>
                </ul>
            </Fragment>
        );
    }

    rightPanel() {
        return (
            <Switch>
                <Route exact path="/zrodla_energii" component={EnergySourcesMainPage} />
                <Route exact path="/zrodla_energii/fotowoltaika" component={Photovoltaics} />
                <Route exact path="/zrodla_energii/kolektory_sloneczne" component={SolarPanels} />
                <Route exact path="/zrodla_energii/kotly_na_biomase" component={BiomassCollectors} />
                <Route exact path="/zrodla_energii/rekuperacja" component={Regeneration} />
                <Route exact path="/zrodla_energii/kotly_gazowe_olejowe" component={OilGasBoilers} />
                <Route path='*' component={PageNotFound} />
            </Switch>
        );
    }

    render() {
        return (
            <div className='EnergySourcesPage'>
                <Page
                    className='EnergySourcesPage-Page'
                    childLeftPanel={this.leftPanel()}
                    childRightPanel={this.rightPanel()}
                />
            </div>
        );
    }
}

