import React, { Component } from 'react';

export default class HeatPumpsPageCWU extends Component {
    render() {
        return (
            <div className="HeatPumpsPageCWU">
                <h4>c.w.u</h4>
                <p>Produkcja ciepłej wody na potrzeby gospodarstwa domowego pochłania ok. 20% zużywanej energii. Warto zatem poszukać ekonomicznego żródła do jej produkcji. Rozwiązaniem jest pompa ciepła do produkcji c.w.u. Pracując w zakresie temepratur -5/-10'C - +45'C praktycznie zaspokaja 70-90% zapotrzebowania na c.w.u. Wykorzystuje to tego celu energię cieplną z powietrza.</p>
                <p className="HeatPumpsPageCWU-aspects">Dodatkowe zalety:</p>
                <ul className="HeatPumpsPageCWU-lists Page-list">
                    <li>Przystępna cena</li>
                    <li>Możliwość wykorzystania ciepła odpadowego z pomieszczeń</li>
                    <li>Mozliwość podłączenia klasycznego pieca poprzez wężownicę spiralną</li>
                    <li>Szeroki zakres pracy - minimalne uzależnienie od warunków atmosferycznych</li>
                </ul>
            </div>
        );
    }
}