import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';

import './Page.scss';

export default class Page extends Component {
    render () {
        const { childLeftPanel, childRightPanel, className } = this.props;

        return (
            <div className={classNames('Page', className)}>
                <div className="Page-panels-container">
                    <div className='Page-panels-container-left'>
                        {childLeftPanel}
                    </div>
                    <div className='Page-panels-container-right'>
                        {childRightPanel}
                    </div>
                </div>
            </div>
        );
    }
}

Page.propTypes = {
    childLeftPanel: PropTypes.node,
    childRightPanel: PropTypes.node,
    className: PropTypes.string
};
