import React, { Component } from 'react';
import classNames from 'classnames';
import isEmail from 'validator/lib/isEmail';

import InputElement from './components/InputElement';
import "./ContactPage.scss";
import Recaptcha from 'react-recaptcha';

const defaultProps = {
    name: '',
    phone: '',
    message: '',
    city: '',
    cityCode: '',
    email: '',
    token: '',
    isLoading: false,
    permission: false,
    error: null,
    confirmationMsg: null,
    isSubmitButtonEnabled: false,
    isTextAreaFocused: false
};

export default class ContactPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...defaultProps
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleTextareaFocus = this.handleTextareaFocus.bind(this);
        this.handleTextareaBlur = this.handleTextareaBlur.bind(this);
        this.submitForm = this.submitForm.bind(this);

        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.handlePermission = this.handlePermission.bind(this);
    }

    isFormValid() {
        const { name, phone, message, city, email, cityCode, permission } = this.state;

        return name !== "" && phone !== '' && city !== "" && message !== "" && cityCode !== "" && permission && (email !== "" && isEmail(email));
    }

    async submitForm(e) {
        e.preventDefault();

        const { token, name, phone, message, city, email, cityCode } = this.state;
        const isFormValid = this.isFormValid();

        if (!isFormValid) {
            const isEmailValid = isEmail(email);

            this.setState({ error: !isEmailValid ? "Proszę wprowadzić poprawny adres email" : "Wszystkie pola są wymagane" });
            return;
        }

        this.setState({ isLoading: true });

        const url = `//pompyciepla-podhale.com.pl/kontakt`;

        const text = `*Imię i nazwisko: ${name}\n*Adres email: ${email}\n*Numer telefonu: ${phone}\n*Treść wiadomosci: ${message}\n*Kod pocztowy: ${cityCode}\n*Miasto: ${city}`;
        const data = { text, captcha: token };

        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),

            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .then(data => {
                const message = data.msg;

                if (!data.wasSuccesful) {
                    this.setState({ error: message, isLoading: false });
                } else {
                    window.grecaptcha.reset();
                    this.setState({ ...defaultProps, confirmationMsg: data.msg, isLoading: false });
                }
            })
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(response) {
        if (response) {
            this.setState({ token: response });
        }
    }


    handleChange(ev, name) {
        this.setState({ [name]: ev.target.value, isSubmitButtonEnabled: true, error: null, confirmationMsg: null });
    }

    handleMessageChange(event) {
        this.setState({ message: event.target.value, isSubmitButtonEnabled: true, error: null, confirmationMsg: null });
    }

    handleTextareaFocus() {
        this.setState({ isTextAreaFocused: true });
    }

    handleTextareaBlur() {
        this.setState({ isTextAreaFocused: false });
    }

    handlePermission(ev) {
        this.setState({ permission: ev.target.checked, isSubmitButtonEnabled: true, error: null, confirmationMsg: null });
    }


    rightPanel() {
        const { name, phone, message, city, email, cityCode, error, isSubmitButtonEnabled, confirmationMsg, isLoading, permission } = this.state;
        const shouldLabelFloat = this.state.isTextAreaFocused || message !== '';


        return (
            <>
                <h4>formularz kontaktowy</h4>
                <form onSubmit={this.submitForm}>
                    <div className='form-wrapper'>
                        <div className="form-wrapper-container">
                            <InputElement
                                type='text'
                                value={name}
                                labelText='Imię i nazwisko'
                                onChange={(event) => this.handleChange(event, 'name')}
                            />
                            <InputElement
                                labelText='Telefon'
                                value={phone}
                                onChange={(event) => this.handleChange(event, 'phone')}
                            />
                            <InputElement
                                value={cityCode}
                                labelText='Kod pocztowy'
                                onChange={(event) => this.handleChange(event, 'cityCode')}
                            />
                        </div>
                        <div className="form-wrapper-container">
                            <InputElement
                                value={city}
                                labelText='Miejscowość'
                                onChange={(event) => this.handleChange(event, 'city')}
                            />
                            <InputElement
                                value={email}
                                labelText='Adres email'
                                onChange={(event) => this.handleChange(event, 'email')}
                            />
                            <div className={classNames('form-textarea', { 'form-textarea--focused': shouldLabelFloat })}>
                                <span>
                                    Treść wiadomości
                                </span>
                                <textarea value={message} onChange={this.handleMessageChange} onFocus={this.handleTextareaFocus} onBlur={this.handleTextareaBlur} />
                            </div>
                        </div>
                    </div>

                    <Recaptcha
                        sitekey="6Ldv8OUUAAAAAB3_aOYwxcbmag8IDqh3ksOKpuna"
                        render="explicit"
                        hl='pl'
                        onloadCallback={this.onLoadRecaptcha}
                        verifyCallback={this.verifyCallback}
                    />
                    <br />
                    {!!error &&
                        <p className='form-error'>{error}</p>
                    }
                    {
                        !!confirmationMsg &&
                        <p className='form-confirmation'>{confirmationMsg}</p>

                    }
                    <div className='permission-checkbox'>
                        <input type='checkbox' checked={permission} onChange={this.handlePermission} />
                        <span>Zgoda na przetwarzanie</span>
                    </div>
                    <div className='permission'>
                        Zapoznałem się z Polityką prywatności serwisu pompyciepla-podhale.com.pl oraz wyrażam zgodę na przetwarzanie przez
                        POMPY CIEPŁA PODHALE Sp. z o.o., ul. Królowej Jadwigi 80A, 34-400 Nowy Targ, udostępnionych przeze mnie danych osobowych
                        na zasadach opisanych w Polityce prywatności dostępnej w Serwisie. Oświadczam, że są mi znane cele przetwarzania danych
                        oraz moje uprawnienia. Niniejsza zgoda może być wycofana w dowolnym czasie poprzez kontakt z Administratorem pod adresem
                        biuro@pompyciepla-podhale.com.pl, bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed
                        jej cofnięciem. Więcej informacji dotyczących przetwarzania danych osobowych - POLITYKA PRYWATNOŚCI
                    </div>
                    {isLoading ?
                        <div className='loader'><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div> :
                        <input type="submit" value="Wyślij" className={classNames('form-button', { 'form-button--enabled': isSubmitButtonEnabled })} />
                    }
                </form>
            </>
        );
    }

    leftPanel() {
        return (
            <>
                <h4>kontakt</h4>
                <span>POMPY CIEPŁA PODHALE</span>
                <span>Sp. z o.o.</span>
                <span>ul. Kościelna 20/4</span>
                <span>34-400 Nowy Targ</span>
                <span><strong>Telefon:</strong></span>
                <span>+48 18 266 92 26</span>
                <span><strong>Email:</strong></span>
                <span>biuro@pompyciepla-podhale.com.pl</span>
            </>
        );
    }
    render() {
        return (
            <div className='ContactPage'>
                {this.rightPanel()}
                <div className='contact-section'>{this.leftPanel()}</div>
                {/* <Page
                    className='ContactPage-Page'
                    childLeftPanel={this.leftPanel()}
                    childRightPanel={this.rightPanel()}
                /> */}
            </div>
        );
    }
}