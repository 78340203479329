import React, { Component } from 'react';

import ariston_kociol from "../../assets/ariston_kociol.jpg";
import buderus_kociol from "../../assets/buderus_kociol.jpg";
import vitodens_300_schnitt from "../../assets/vitodens_300_schnitt.jpg";
import DSC00602 from "../../assets/DSC00602.jpg";
import IMG_8642 from "../../assets/IMG_8642.jpg";
import Ariston_logo from "../../assets/Ariston_logo.jpeg";
import viessmann_logo from "../../assets/viessmann_logo.jpg";
import dedietrich_logo from "../../assets/dedietrich_logo.jpeg";
import buderus_logo from "../../assets/buderus_logo.jpeg";



import "./OilGasBoilers.scss";

export default class OilGasBoilers extends Component {
    render () {
        return (
            <div className="OilGasBoilers">
                <h4>kotły gazowe/olejowe</h4>
                <p>Kotły gazowe jak i na olej opałowy występują obecnie jako jedyne źródło ciepła instalacji grzewczej lub jako uzupełnienie/źródło szczytowe powietrznych pomp ciepła. Niewątpliwą zaletą jest ich praktycznie bezobsługowa praca oraz możliwość pracy w układach z zarówno nisko jak i wyskokotemperaturową instalacją centralnego ogrzewania.</p>
                <img src={ariston_kociol} alt='ariston_kociol'></img>
                <img src={buderus_kociol} alt='buderus_kociol'></img>
                <img src={vitodens_300_schnitt} alt='vitodens_300_schnitt'></img>
                <p>Jeżeli posiadamy już gotowy przyłącz gazowy i szacht kominowy montaż nowoczesnego gazowego kotła kondensacyjnego jako źródło energii grzewczej jest wyborem jak najbardziej uzasadnionym ekonomicznie jaki i praktycznie. Kompaktowe rozmiary obecnych urządzeń, możliwość modulacji mocy grzewczej, nowoczesne sterowanie oraz nieskie koszty eksploatacji uzasadniają ten wybór.</p>
                <img className='big-image' src={DSC00602} alt='DSC00602'></img>
                <p>Jako Firma, która na przestrzeni ponad 20 lat wykonała kilkaset instalacji tego typu - od kilku kilowatowych do magawatowych bloków grzewczych - możemy bazując na naszym doświadczeniu zaproponować kompleksowe wykonanie nowoczesnych kotłowni gazowo-olejowych. Łącznie z wykonaniem instalacji gazowej przez uprawnionych do tego instalatorów oraz odbiór i uruchomienie.</p>
                <img className='big-image' src={IMG_8642} alt='IMG_8642'></img>
                <p><u>Najczęściej montujemy produkty Firm:</u></p>
                <img className='logo' src={Ariston_logo} alt='Ariston_logo'></img>
                <img className='logo' src={viessmann_logo} alt='viessmann_logo'></img>
                <img className='logo' src={dedietrich_logo} alt='dedietrich_logo'></img>
                <img className='logo' src={buderus_logo} alt='buderus_logo'></img>
                <p className='OilGasBoilers-highlight'>
                    <span>Zapraszamy!</span>
                    <span>Skontaktuj się z Nami i zamów bezpłatną wycenę instalacji!</span>
                </p>
            </div>
        );
    }
} 