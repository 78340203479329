import React, { Component } from 'react';

import dom from "../../assets/dom.jpg";
import blok from "../../assets/blok.jpg";
import kolektory_jablonka from "../../assets/kolektory_jablonka.jpg";
import układ_kolektorów from "../../assets/układ_kolektorów.jpg";

import "./SolarPanels.scss";

export default class SolarPanels extends Component {
    render () {
        return (
            <div className="SolarPanels">
                <h4>kolektory słoneczne</h4>
                <p>Energia solarna, pozyskiwana poprzez kolektory słoneczne, służąca do wytwarzania ciepła, pomimo rozwoju alternatywnych źródeł takich jak pompy ciepła, czy też fotowoltaika, nadal cieszy się dużą popularnością wśród naszych klientów. Może o tym świadczyć ponad 50 zamontowanych i uruchomionych przez naszą Firmę, w przeciągu kilku ostatnich lat, instalacji solarnych. Projekty obejmowały zarówno podgrzew c.w.u. w niewielkich instalacjach domowych jak duże instalacje w pensjonatach, czy też obiektach użyteczności publicznej.</p>
                <img className="first-img" src={blok} alt='blok'></img>
                <img src={kolektory_jablonka} alt='kolektory_jablonka'></img>
                <img src={dom} alt='dom'></img>
                <p>Obecnie głównym czynnikiem zakupu i montażu instalacji kolektorów słonecznych oprócz aspektu ekonomicznego jest, szczególnie w przypadku kotłowni węglowych, możliwość bezobsługowego wytwarzania c.w.u. w okresie letnim.</p>
                <p>Bazując na naszym doświadczeniu możemy Państwu zaproponować montaż płaskich kolektorów słonecznych o różnej powierzchni od 2,05 do 2,65 m2 w układzie pionowym jak i poziomym. Montaż na dachach skośnych, płaskich, na konstrukcjach naziemnych czy też w połaci dachowej. W układach przygotowania c.w.u., buforowych i wspomagania centralnego ogrzewania.</p>
                <img className="uklad-kolektorow" src={układ_kolektorów} alt='układ_kolektorów'></img>
                <p className='SolarPanels-highlight'>
                    <span>Zapraszamy!</span>
                    <span>Skontaktuj się z Nami i zamów bezpłatną wycenę instalacji!</span>
                </p>
            </div>
        );
    }
} 