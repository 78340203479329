import React, { Component } from 'react';
import Page from '../Page/Page';

import "./UpgradeOilBoilerRoom.scss";

export default class UpgradeOilBoilerRoom extends Component {
    rightPanel() {
        return (
            <div className="UpgradeOilBoilerRoom-right">
                <h4>zmodernizuj swoją kotłownię olejową</h4>
                <p>W przypadku kotłowni opalanych olejem opałowym montaż pompy ciepła jako biwalentnego źródła ogrzewania staje się praktycznie koniecznością.</p>
                <p>Nowoczesne powietrzne pompy ciepła osiągają temperaturę zasilania na poziomie ok 58'C i pracę do temperatur -20'C. Dzięki temu pompa ciepła pracuje, zachowując swoją efektywność, praktycznie przez cały sezon grzewczy. Kocioł olejowy pozostaje jako szczytowe źródło ciepła.</p>
                <p>Co z tego wynika? Posłużmy się tu prostym przykładem:</p>
                <p>Wykorzystując kocioł olejowy do ogrzewania domu o powierzchni 220m2 roczne zużycie oleju opałowego wynosi ok. 3200 l, co skutkuje wydatkiem 13 500zł.</p>
                <p>Montaż powietrznej pompy ciepła w przypadku modernizacji tego typu kotłowni wyniesie ok 29 000 zł.</p>
                <p>Wykorzystanie pompy ciepła daje nam spadek kosztów eksploatacyjnych o ok. 50% i zwrot z inwestycji w przeciągu 3-5 lat.</p>
                <h4>nie czekaj!</h4>
                <p>W celu wylicznia oszczędności oraz doboru pompy ciepła będziemy potrzebowali kilku informacji:</p>
                <ul className='Page-list'>
                    <li>zużycie oleju</li>
                    <li>moc kotła</li>
                    <li>rodzaj instalacji (ilość obiegów grzewczych i ich rodzaj)</li>
                    <li>powierzchnia budynku</li>
                    <li>jaką – w praktyce – ustawiasz temperaturę na zasilaniu c.o.</li>
                </ul>
                <p>Zbierz powyższe dane i skontaktuj się na Nami.
                    Wykonamy szybką kalkulację i jeśli byliby Państwo zainteresowani – po wizji lokalnej - szczegółowy kosztorys prac.
                </p>
                <p className='UpgradeOilBoilerRoom-highlight'>
                    <span>zmodernizuj kotłownię olejową!</span>
                </p>
            </div>
        );
    }

    render() {
        return (
            <div className='UpgradeOilBoilerRoom'>
                <Page
                    className='UpgradeOilBoilerRoom-wrapper'
                    childLeftPanel={null}
                    childRightPanel={this.rightPanel()}
                />
            </div>
        );
    }
}