import React, { Component } from 'react';

import "./HeatPumpsPageAspectsPage.scss";

export default class HeatPumpsPageAspectsPage extends Component {
    render() {
        return (
            <div className="HeatPumpsPageAspectsPage">
                <h4>Dlaczego pompa ciepła</h4>
                <p>Postarajmy się podzielić argumenty przemawiające za montażem pomp ciepła na 3 główne aspekty:</p>
                <p className="HeatPumpsPageAspectsPage-aspects">1. ekologia</p>
                <ul className="HeatPumpsPageAspectsPage-lists Page-list">
                    <li>W pełni ekologiczne źródło ciepła</li>
                    <li>Nie emituje zanieczyszczeń, dymu, popiołu, sadzy</li>
                    <li>W ponad 75% wykorzystuje odnawialne źródła energii - grunt, powietrze, wodę</li>
                </ul>
                <p className="HeatPumpsPageAspectsPage-aspects">2. ekonomia</p>
                <ul className="HeatPumpsPageAspectsPage-lists Page-list">
                    <li>Brak konieczności budowy pomieszczenia na opał lub zbiorników</li>
                    <li>Brak kobieczności budowy komina</li>
                    <li>Brak konieczności budowy przyłącza gazowego</li>
                    <li>Koszty eksploatacji porównywalne z węglem i ekogroszkiem</li>
                    <li>3 – 5 letni zwrot inwestycji w przypadku oleju opałowego</li>
                </ul>
                <p className="HeatPumpsPageAspectsPage-aspects">3. obsługa</p>
                <ul className="HeatPumpsPageAspectsPage-lists Page-list">
                    <li>Praktycznie bezobsługowe urządzenie</li>
                    <li>Możliwość sterowania przez internet</li>
                    <li>Cicha praca i estetyka wykonania</li>
                </ul>
            </div>
        );
    }
}