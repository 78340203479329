import React, { Component } from 'react';

import pellet from "../../assets/pellet.jpg";
import kocioł from '../../assets/kocioł.jpg';
import kolektorSłoneczny from '../../assets/kolektorSłoneczny.jpg';

export default class EnergySourcesMainPage extends Component {  
    render() {
        return (
            <div className="EnergySourcesMainPage">
                <h4>źródła energii</h4>
                <div className='EnergySourcesPage-descriptions'>
                    <p>Energooszczędne i ekologiczne źródła energii to nie tylko pomy ciepła.</p>
                    <p>Kotły na pellet, gaz czy też kolektory słoneczne to również inwestycja w oszczędność i wygodę użytkowania. Mogą one również stanowić znakomite uzupełnienienie dla instalacji powietrznych pomp ciepła.</p>
                    <div className='EnergySourcesPage-descriptions-images'>
                        <img src={pellet} alt='źródła energii'></img>
                        <img src={kocioł} alt='źródła energii'></img>
                        <img src={kolektorSłoneczny} alt='źródła energii'></img>
                    </div>
                </div>
            </div>
        );
    }
}