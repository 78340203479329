import React, { Component } from 'react';

import { NavLink } from "react-router-dom";

import kuchnia from './assets/kuchnia.jpg';
import rekuperator from './assets/rekuperator.jpg';
import blog from './assets/blog.jpg';
import pomps from './assets/pomps.jpg';

import "./KnowledgeCenter.scss";


export default class KnowledgeCenter extends Component {
    render() {
        return (
            <div className='KnowledgeCenter'>
                <h4>centrum wiedzy</h4>
                <div className='KnowledgeCenter-descriptions'>
                    <div className='KnowledgeCenter-descriptions-container'>
                        <img src={kuchnia} alt='kuchnia'></img>
                        <h4>
                            <NavLink className='KnowledgeCenter-link' to='/pompy_ciepla/dlaczego_pompa_ciepla'>dlaczego pompa ciepła</NavLink>
                        </h4>
                        <p>Pompy ciepła mimo większych początkowych nakładów finansowych zwracają się po kilku latach eksploatacji. Do tego są ciche, estetyczne i bezpieczne.</p>
                    </div>
                    <div className='KnowledgeCenter-descriptions-container right'>
                        <img src={rekuperator} alt='rekuperator'></img>
                        <h4>aktualnosci
                            {/* <a href='#'>aktualnosci</a> */}
                        </h4>
                        <p>Bądź na bieżąco w tematyce pomp ciepła. Śledź z nami nowości, ciekawostki i rozwój tej technologii.</p>
                    </div>
                </div>
                <div className='KnowledgeCenter-descriptions'>
                    <div className='KnowledgeCenter-descriptions-container'>
                        <img src={blog} alt='kuchnia'></img>
                        <h4>
                            blog
                            {/* <a href='#'>blog</a> */}
                        </h4>
                        <p>Nasze rozważania na temat technologii pomp ciepła. Opisy, recenzje, wymiana doświadczeń.</p>
                    </div>
                    <div className='KnowledgeCenter-descriptions-container right'>
                        <img src={pomps} alt='rekuperator'></img>
                        <h4>
                            <NavLink className='KnowledgeCenter-link' to='/zmodernizuj_swoja_kotlownie'>zmodernizuj kotlownię olejową</NavLink>
                        </h4>
                        <p>Modernizacja kotłowni olejowej. Zmiejsz wydatki na ogrzewanie.</p>
                    </div>
                </div>
            </div>
        );
    }
}