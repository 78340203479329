import React, { Component } from "react";
import './PrivacyPolicy.scss';

export default class PrivacyPolicy extends Component {
    render() {
        return (
            <div className='PrivacyPolicy'>
                <h1>polityka prywatności</h1>
                <p><u>Szanowni Państwo,</u></p>
                <p>W dniu 25 maja 2018 roku weszła w życie Ustawa z dnia 10 maja 2018 roku o ochronie danych osobowych Dz.U.2018 poz. 1000,
                która reguluje zasady ochrony danych osobowych zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27.04.2016 r.
                w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia
                dyrektywy 95/46/WE tzw. RODO.
                </p>
                <p><u>Informujemy zatem, że:</u></p>
                <p>
                    Administratorem Państwa danych osobowych jest firma Pompy Ciepła Podhale Sp. z o.o. z siedzibą w Nowym Targu przy ul. Królowej Jadwigi 80A,
                    wpisana do KRS pod numerem 0000544210, NIP 7352861199 REGON 123238539.
                </p>
                <p>Kontakt mailowy: BIURO@POMPYCIEPLA-PODHALE.COM.PL</p>
                <p>Kontakt telefoniczny: +48 182669226.</p>
                <p>Mają Państwo prawa, w stosunku do danych osobowych, które Państwa dotyczą, czyli:</p>
                <ul>
                    <li>prawo do żądania od administratora <u>dostępu</u> do Państwa danych osobowych oraz otrzymania ich kopii,</li>
                    <li>prawo do żądania od administratora <u>sprostowania</u> (poprawienia) Państwa danych osobowych,</li>
                    <li>prawo do żądania od administratora <u>usunięcia</u> Państwa danych osobowych w sytuacji gdy przetwarzanie nie następuje w celu wywiązania się z obowiązku wynikającego z przepisu prawa,</li>
                    <li>prawo do żądania od administratora <u>ograniczenia</u> przetwarzania Państwa danych osobowych,</li>
                    <li>prawo do wniesienia <u>sprzeciwu</u> wobec przetwarzania Państwa danych osobowych,</li>
                    <li>prawo do <u>przenoszenia</u> Państwa danych osobowych,</li>
                    <li>prawo do <u>wniesienia</u> skargi do organu nadzorczego - Prezesa Urzędu Ochrony Danych Osobowych, w przypadku gdy uznają Państwo, iż przetwarzanie danych osobowych narusza przepisy RODO.</li>
                </ul>
                <p>Państwa dane osobowe przetwarzane są, ponieważ spełniony został <u>co najmniej jeden z poniższych warunków art. 6 ust. 1 RODO tj.:</u></p>
                <ul>
                    <li>wyrazili Państwo zgodę na przetwarzanie swoich danych osobowych w jednym lub większej liczbie określonych celów,</li>
                    <li>przetwarzanie jest niezbędne do wykonania umowy, w której Państwo są stroną, lub do podjęcia działań na Państwa żądanie przed zawarciem umowy,</li>
                    <li>przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na administratorze,</li>
                    <li>przetwarzanie jest niezbędne do ochrony Państwa żywotnych interesów lub innej osoby fizycznej,</li>
                    <li>przetwarzanie jest niezbędne do wykonania zadania realizowanego w interesie publicznym lub w ramach sprawowania władzy publicznej powierzonej administratorowi,</li>
                    <li>przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora lub przez osobę trzecią.</li>
                </ul>
                <p>Państwa dane osobowe przetwarzane są w celu:</p>
                <ul>
                    <li>kontaktu z Państwem,</li>
                    <li>sporządzenia oferty,</li>
                    <li>sporządzenia umowy,</li>
                    <li>wykonania zadań zawartych w ofercie/umowie,</li>
                    <li>obsługi powykonawczej m.in. przeglądów wykonanych instalacji, serwisowania, reklamacji.</li>
                </ul>
                <p>
                    Państwa dane osobowe udostępniamy tylko podmiotom, które ich potrzebują, abyśmy mogli zagwarantować wysoką jakość produktu i/lub usługi. Dane są przetwarzane wyłącznie z polecenia administratora. Nie udostępniamy danych osobowych żadnym podmiotom zewnętrznym na użytek własny, a jedynie do realizacji naszych zadań potrzebnych do wywiązania się z umowy wobec Państwa.
                    Państwa dane osobowe nie są przetwarzane z wykorzystaniem systemów zautomatyzowanego podejmowania decyzji, w tym profilowania.
                    Państwa dane osobowe nie będą przechowywane dłużej niż będzie to konieczne do osiągnięcia celów, do jakich zostały zebrane oraz nie dłużej niż jest to określone prawem.
                </p>
                <p>Z poważaniem</p>
                <p>Pompy Ciepła Podhale Sp.  z o.o.</p>
            </div>
        );
    }
}