import React, { Component } from 'react';

import rodzaje from "../../assets/rodzaje.jpg";

import './HeatPumpsPageTypesPage.scss';

export default class HeatPumpsPageTypesPage extends Component {
    render() {
        return(
            <div className="HeatPumpsPageTypesPage">
                <h4>rodzaje pomp ciepła</h4>
                <div className="HeatPumpsPageTypesPage-description HeatPumpsPage-descriptions">
                    <p>Najprościej sklasyfikować pompy ciepła ze względu na rodzaj dolnego źródła/konstrukcję pompy.</p>
                    <img src={rodzaje} alt='kuchnia'></img>                
                </div>
            </div >
        );
    }
}