import React, { Component } from 'react';

import podłogowe from '../../assets/podłogowe.bmp';

export default class FittingsPageMainPage extends Component {    
    render() {
        return (
            <div className="FittingsPageMainPage">
                <h4>instalacje</h4>
                <div className='FittingsPage-descriptions'>
                    <p>Chcąc w pełni wykorzystać możliwości pracy pompy ciepła lub innego niskotemperaturowego źródła ogrzewania najkorzystniej je połączyć z nowoczesnymi instalacjami grzewczymi.</p>
                    <p>Optymalnym wyborem są tu systemy ogrzewania płaszczyznowego – podłogowego, ściennego lub sufitowego lub klimakonwektory</p>
                    <div className='FittingsPage-descriptions-images'>
                        <img src={podłogowe} alt='Instalacje'></img>
                    </div>
                </div>               
            </div>
        );
    }
}