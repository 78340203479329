import React, { Component } from 'react';

import "./HeatPumpsPageGround.scss";

import gruntowe from "../../assets/gruntowe.jpg";
import nibe from "../../assets/nibe.jpg";

export default class HeatPumpsPageGround extends Component {
    render () {
        return (
            <div className="HeatPumpsPageGround">
                <h4>pompy gruntowe</h4>
                <p>
	            <strong>Pompy gruntowe</strong> stosowane są zazwyczaj w dużych instalacjach grzewczych o mocach setek kW, a bywa też że w większych. Pompy te mogą pracować jako mono walentne, i mono energetyczne, czyli sama pompa może obsługiwać instalację grzewczą, bez wspomagania się w szczytach dodatkowym źródłem ciepła. Największym problemem&nbsp; przy montażu tych pomp, jest prawidłowe wykonanie dolnego źródła, które stanowi podstawowy element instalacji. Istnieje wiele niepoprawnie wykonanych dolnych źródeł, przez co pompy ciepła nie osiągają zakładanych parametrów temperaturowych i ekonomicznych.</p>
                <img src={gruntowe} alt='pompy gruntowe'></img>
                <p>
                Coraz częściej, gruntowe pompy ciepła, wykorzystują wody technologiczne odpadowe, ale o dobrych parametrach temperaturowych, jako dolne źródło. Przykładem takiego wykorzystania wód technologicznych, są oczyszczalnie ścieków, gdzie ścieki oczyszczone mają temperaturę zimą około 6º C, i świetnie nadają się do zasilania pompy ciepła. Istnieje wiele dziedzin przemysłu, gdzie można mieć dostęp do wód technologicznych o wysokich temperaturach. Takim przykładem są kopalnie które wypompowują codziennie tysiące metrów sześciennych wody o temperaturze około 18º C, która użyta do pomp ciepła, dała by nam ogromne ilości taniej energii, którą można by wykorzystać do ogrzewania mieszkań.
                </p>
                <img className="nibe" src={nibe} alt='nibe'></img>
            </div>
        );
    }
} 