import React, { useState, useEffect } from 'react';
import { Route, Switch, NavLink } from "react-router-dom";

import { linksList } from "./linksList";
import realizations from './realizationsData.json';

import Page from '../Page/Page';
import RealizationToDisplay from "./components/RealizationToDisplay";
import PageNotFound from '../PageNotFound/PageNotFound';
import RealizationMap from './RealizationMap';
import './RealizationsPage.scss';

const getModifiedList = () => {
    return linksList.map(({ label, url }) => {
        const path = url.substring(url.lastIndexOf('/') + 1);
        url = `/realizacje/${path}`;

        return {
            label,
            url
        };
    });
}


export default function RealizationsPage() {
    const [currentUrl, setCurrentUrl] = useState(window.location.pathname || "/realizacje");
    const [realization, setRealization] = useState({});


    const handleActiveLink = (match, location) => {
        if (!match) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        handleRealizationToDisplay(window.location.pathname);
    }, []);

    const handleRealizationToDisplay = (url) => {
        let realizationUrl;

        const realizationToDisplay = realizations.find(realization => {
            realizationUrl = realization.url.substring(realization.url.lastIndexOf('/') + 1)

            return `/realizacje/${realizationUrl}` === url
        });

        setCurrentUrl(`/realizacje/${realizationUrl}`);
        setRealization(realizationToDisplay);
    }

    const handleRealiationClick = (url) => {
        handleRealizationToDisplay(url);
    }

    const leftPanel = () => {
        const menuList = getModifiedList();

        return (
            <div className='Realization-menu-list'>
                <h4>menu</h4>
                <ul className='RealizationsPage-links Page-list'>
                    {menuList.map(({ url, label }, i) => (
                        <li key={i} onClick={() => handleRealizationToDisplay(url)}>
                            <NavLink to={url} isActive={handleActiveLink}>
                                {label}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    const rightPanel = () => {
        return (
            <>
                {/* <RealizationMap cities={linksList} onRealizationClikc={(url) => handleRealiationClick(url)} /> */}

                <Switch>
                    <Route exact path={currentUrl} component={() => <RealizationToDisplay realization={realization} />} />
                    <Route exact path="/realizacje" component={() => <RealizationMap cities={linksList} onRealizationClikc={(url) => handleRealiationClick(url)} />} />
                    <Route path='*' component={PageNotFound} />
                </Switch>
            </>
        );
    }

    return (
        <div className='RealizationsPage'>
            <Page
                className='RealizationsPage-Page'
                childLeftPanel={leftPanel()}
                childRightPanel={rightPanel()}
            />
        </div>
    );
}