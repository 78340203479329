import React, { Component } from 'react';

import podlogowe4 from "../../assets/podlogowe4.jpg";
import podlogowe3 from "../../assets/podlogowe3.jpg";
import podlogowe5 from "../../assets/podlogowe5.jpg";
import podłogowesucha from "../../assets/podłogowesucha.jpg";
import podłogoweautomatyka from "../../assets/podłogoweautomatyka.jpg";

import './FittingsFloorHeating.scss';

export default class FittingsFloorHeating extends Component {
    render () {
        return (
            <div className="FittingsFloorHeating">
                <h4>ogrzewanie podłogowe</h4>
                <p>Najczęściej spotykaną formą ogrzewania płaszczyznowego jest instalacja grzewcza ogrzewania podłogowego. Charakteryzuje się ona rozsądnym stosunkiem wydajnością do ceny montażu. Możemy bez obaw ją stosować w połączeniu z każdym rodzajem podłóg - ceramika, marmur, drewno, wykładzina.</p>    
                <img src={podlogowe4} alt='ogrzewanie podlogowe'></img>
                <p><u>Główne zalety ogrzewania podłogowego to:</u></p>
                <ul className='Page-list'>
                    <li>Znakomita współpraca z alternatywnymi źródłami energii – pompami ciepła i kotłami kondensacyjnymi.</li>
                    <li>Niskie koszty eksploatacji instalacji grzewczej.</li>
                    <li>Optymalny rozkład temperatur w pomieszczeniu.</li>
                    <li>Instalacja niewidoczna dla użytkownika – możliwość dowolnej aranżacji wnętrz.</li>
                    <li>Odporność na procesy korozyjne</li>
                    <li>Możliwość chłodzenia podłogi latem – we współpracy z nowoczesnymi pompami ciepła.</li>
                </ul>
                <img src={podlogowe3} alt='ogrzewanie podlogowe'></img>
                <p>Posiadając wieloletnie doświadczenie i wiedzę niezbędną do prawidłowego wykonania tego typu instalacji możemy Państwu <u>zaoferować kompleksową usługę w której zawieramy:</u></p>
                <p className="FittingsFloorHeating-highlight"><span>Doradztwo, projekt, wykonanie oraz regulację instalacji ogrzewania podłogowego.</span></p>
                <img src={podlogowe5} alt='ogrzewanie podlogowe'></img>
                <p>Kompletna oferta oraz znajomość systemów podłogowych pozwala nam na profesjonalny montaż instalacji ogrzewania podłogowego, w domach jednorodzinnych, rezydencjach, obiektach sakralnych czy też halach sportowych</p>
                <p>Dzięki bogatej ofercie producentów, oraz naszej wiedzy, z powodzeniem montujemy instalację ogrzewania podłogowego na:</p>
                <ul className='Page-list'>
                    <li>Tradycyjnych wylewkach – metodą „mokrą”</li>
                    <li>Podłogach o konstrukcji drewnianej z pustką powietrzną</li>
                    <li>Podłogach wykonywanych metodą suchego jastrychu przy remontach i adaptacjach</li>
                </ul>
                <img src={podłogowesucha} alt='ogrzewanie podlogowe'></img>
                <p>Dodatkowo możemy zaoferować, dopasowany do Państwa potrzeb, system automatyki - przewodowej jak i bezprzewodowej – służący do sterowania obiegami/strefami grzewczymi ogrzewania podłogowego. Właściwie dobrany system sterowania zwiększa komfort korzystania z systemu grzewczego, oraz redukuje koszty jego eksploatacji.</p>
                <img src={podłogoweautomatyka} alt='ogrzewanie podlogowe'></img>
                <p className="FittingsFloorHeating-highlight">
                    <span>ZAUFAJ DOŚWIADCZENIU - ZAPRASZAMY DO WSPÓŁPRACY</span>
                </p>
                <p className="FittingsFloorHeating-highlight">
                    <span>WYKORZYSTAJ W PEŁNI ZALETY OGRZEWANIA PODŁOGOWEGO</span>
                </p>
            </div>
        );
    }
} 