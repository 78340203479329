import React, { Component } from 'react';

export default class FittingsCeilingHeating extends Component {
    render () {
        return (
            <div className="FittingsCeilingHeating">
                <h4>ogrzewanie sufitowe</h4>
                <p>Tekst w przygotowaniu.</p>                
            </div>
        );
    }
} 