import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


export default class InputElement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFocused: false
        };

        this.handleFocus = this.handleFocus.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
    }

    handleFocus(e) {
        this.setState({ isFocused: true });
    }

    handleOnBlur() {
        this.setState({ isFocused: false });
    }


    render() {
        const { value, onChange, type = 'text', labelText } = this.props;

        const shouldLabelFloat = value !== "" || this.state.isFocused;

        return (
            <div className={classNames('ContactPage-form', { 'ContactPage-form--isFocused': shouldLabelFloat })}>
                <label>
                    <span>{labelText}</span>
                    <input value={value} type={type} onFocus={this.handleFocus} onBlur={this.handleOnBlur} onChange={onChange} />
                </label>
            </div>
        );
    }
}

InputElement.propTypes = {
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string,
    labelText: PropTypes.string.isRequired
};