import React, { Component } from 'react';

import fotwoltaika from "../../assets/fotwoltaika.jpg";
import dom_w_przekroju from "../../assets/dom_w_przekroju.jpg";
import fotowolaika_dom from "../../assets/fotowolaika_dom.jpg";
import Fotowoltaika_dach_płaski from "../../assets/Fotowoltaika_dach_płaski.jpg";
import Fotowoltaika_grunt from "../../assets/Fotowoltaika_grunt.jpg";

import "./Photovoltaics.scss";

export default class Photovoltaics extends Component {
    render () {
        return (
            <div className="Photovoltaics">
                <h4>trochę teorii</h4>
                <p>Instalacje fotowoltaiczne, służące do produkcji prądu, stają się stałym elementem systemu oszczędzania energii w gospodarstwach domowych.Wychodząc na przeciw oczekiwaniom klientów pragniemy Państwu zaoferować, profesjonalny dobór, montaż oraz uruchomienie instalacji fotowoltaicznych.</p>
                <img src={fotwoltaika} alt='fotwoltaika'></img>
                <p>Uwzględniając obecne uwarunkowania prawne, ilość miejsca potrzebnego na moduły oraz okres zwrotu kosztów jakie ponieśliśmy na budowę systemu najlepiej zbudować instalację fotowoltaiczną dopasowaną do realnego zapotrzebowania domu na energię elektryczną. Dla większości domów są to przeważnie instalacje wielkości 3,0 – 4,5 kW.</p>
                <p><u>Typowa instalacja składa się z następujących elementów:</u></p>
                <p>Sieci energetycznej. (1)</p>
                <p>Dwukierunkowego licznika prądu umożliwiającego zliczenie prądu oddanego do sieci energetycznej. (2)</p>
                <p>Inwertera, który służy do przetworzenia prądu stałego DC wytwarzanego przez moduły na prąd zmienny AC – wykorzystywany w domowej instalacji. (3)</p>
                <p>Modułów fotowoltaicznych – obecnie najczęściej stosowane są moduły krzemowe polikrystaliczne o mocy 245-260W. (4)</p>
                <p>Odbiorników prądu w domowej instalacji. (5)</p>
                <img src={dom_w_przekroju} alt='dom_w_przekroju'></img>
                <p>Kolejną ważną kwestią jest okres w jakim nasza instalacja fotowoltaiczna powinna skutecznie pracować. Szacuje się, że powinno to być min 20lat. Warto zatem bazować na sprawdzonych komponentach markowych producentów, które pozwolą na wieloletnią bezawaryjna pracę systemu.</p>
                <p>Szukając możliwie najkorzystniejszego umiejscowienia naszej instalacji fotowoltaicznej, potrzebujemy elastycznego i pewnego systemu montażowego. System montażowy, na którym mocujemy instalacje, umożliwia stabilny i pewny montaż modułów na:</p>
                <p>Dachach skośnych</p>
                <img src={fotowolaika_dom} alt='fotowolaika_dom'></img>
                <p>Dachach płaskich</p>
                <img src={Fotowoltaika_dach_płaski} alt='Fotowoltaika_dach_płaski'></img>
                <p>Konstrukcjach naziemnych</p>
                <img src={Fotowoltaika_grunt} alt='Fotowoltaika_grunt'></img>
                <p className="Photovoltaics-our-offer"><span>Zapraszamy do skorzystania z Naszej oferty!</span></p>
            </div>
        );
    }
} 