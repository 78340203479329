import React, { Component } from 'react';

export default class FittingsFanCoilUnits extends Component {
    render () {
        return (
            <div className="FittingsFanCoilUnits">
                <h4>klimakonwektory</h4>
                <p>Tekst w przygotowaniu.</p>                
            </div>
        );
    }
} 