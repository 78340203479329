import React, { Component } from 'react';
import { Route, NavLink, Switch } from "react-router-dom";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ScrollToTop from "../ScrollToTop/ScrollToTop";
import UpgradeOilBoilerRoom from '../UpgradeOilBoilerRoom/UpgradeOilBoilerRoom';
import GrantsPage from '../GrantsPage/GrantsPage';
import AboutUsPage from '../AboutUsPage/AboutUsPage';
import HeatPumpsPage from '../HeatPumpsPage/HeatPumpsPage';
import EnergySourcesPage from "../EnergySourcesPage/EnergySourcesPage";
import FittingsPage from "../FittingsPage/FittingsPage";
import RealizationsPage from '../RealizationsPage/RealizationsPage';
import HomePage from "../HomePage/HomePage";
import ContactPage from "../ContactPage/ContactPage";
import PageNotFound from '../PageNotFound/PageNotFound';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';

import logo from './assets/logo.png';
import "./TopBar.scss";

function NotFoundPage() {
  return <PageNotFound />
}

function Index() {
  return <HomePage />;
}

function About() {
  return <AboutUsPage />;
}

function Grants() {
  return <GrantsPage />;
}

function HeatPumps() {
  return <HeatPumpsPage />;
}

function EnergySources() {
  return <EnergySourcesPage />
}

function Fittings() {
  return <FittingsPage />
}

function Realizations() {
  return <RealizationsPage />
}

function Contact() {
  return <ContactPage />
}

function PrivacyPolicyPage() {
  return <PrivacyPolicy />
}

const getCurrentPage = (url) => {
  if (url === 'o_nas') {
    return 'o nas';
  } else if (url === 'dotacje_1') {
    return 'dotacje';
  } else if (url === 'pompy_ciepla') {
    return 'pompy ciepła';
  } else if (url === 'zrodla_energii') {
    return 'źródła energii';
  } else if (url === "") {
    return 'strona główna'
  }

  return url;
}

export class TopBar extends Component {
  constructor(props) {
    super(props);

    const activePage = (window.location.pathname).substr(1);

    this.state = {
      activePage: getCurrentPage(activePage),
      showNavList: false
    };

    this.handleActiveLink = this.handleActiveLink.bind(this);
    this.handleNav = this.handleNav.bind(this);
    this.toggleDropDown = this.toggleDropDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener("popstate", this.onBackButtonEvent)
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onBackButtonEvent)
  }

  onBackButtonEvent = () => {
    const activePage = (window.location.pathname).substr(1);

    this.setState({
      activePage: getCurrentPage(activePage)
    });
  }

  toggleDropDown() {
    this.setState({ showNavList: !this.state.showNavList });
  }

  handleNav(val) {
    const activePage = getCurrentPage(val);
    this.setState({ activePage, showNavList: false });

  }

  handleActiveLink(match, location) {
    if (!match) {
      return false;
    }
    return true;
  }

  render() {
    return (
      <>
        <ScrollToTop />
        <div className='TopBar'>
          <div className='TopBar-container'>
            <div className="TopBar-container-logo">
              <a href="/">
                <img src={logo} className='TopBar-container-logo-img' alt='Logo' />
              </a>
            </div>
            <div className='TopBar-container-links'>
              <ul className='TopBar-container-links-list'>
                <li>
                  <NavLink onClick={() => this.handleNav('/')} isActive={this.handleActiveLink} to="/">
                    <FontAwesomeIcon icon={faHome} />
                  </NavLink>
                </li>
                <li><NavLink onClick={() => this.handleNav('o_nas')} isActive={this.handleActiveLink} to="/o_nas">o nas</NavLink></li>
                <li><NavLink onClick={() => this.handleNav('dotacje_1')} isActive={this.handleActiveLink} to="/dotacje_1">Dotacje</NavLink></li>
                <li><NavLink onClick={() => this.handleNav('pompy_ciepla')} isActive={this.handleActiveLink} to="/pompy_ciepla">pompy ciepła</NavLink></li>
                <li><NavLink onClick={() => this.handleNav('zrodla_energii')} isActive={this.handleActiveLink} to="/zrodla_energii">źródła energii</NavLink></li>
                <li><NavLink onClick={() => this.handleNav('instalacje')} isActive={this.handleActiveLink} to="/instalacje">instalacje</NavLink></li>
                <li><NavLink onClick={() => this.handleNav('realizacje')} isActive={this.handleActiveLink} to="/realizacje">realizacje</NavLink></li>
                <li><NavLink onClick={() => this.handleNav('kontakt')} isActive={this.handleActiveLink} to="/kontakt">kontakt</NavLink></li>
              </ul>

              <div className='TopBar-selectMenu'>
                <span className='activePage'>{this.state.activePage}</span>
                <div className='chevron-wrapper' onClick={this.toggleDropDown}><i className="chevron"></i></div>
                {this.state.showNavList &&
                  <>
                    <ul>
                      <li><NavLink onClick={() => this.handleNav('o_nas')} isActive={this.handleActiveLink} to="/o_nas">o nas</NavLink></li>
                      <li><NavLink onClick={() => this.handleNav('dotacje')} isActive={this.handleActiveLink} to="/dotacje_1">Dotacje</NavLink></li>
                      <li><NavLink onClick={() => this.handleNav('pompy ciepła')} isActive={this.handleActiveLink} to="/pompy_ciepla">pompy ciepła</NavLink></li>
                      <li><NavLink onClick={() => this.handleNav('źródła energii')} isActive={this.handleActiveLink} to="/zrodla_energii">źródła energii</NavLink></li>
                      <li><NavLink onClick={() => this.handleNav('instalacje')} isActive={this.handleActiveLink} to="/instalacje">instalacje</NavLink></li>
                      <li><NavLink onClick={() => this.handleNav('realizacje')} isActive={this.handleActiveLink} to="/realizacje">realizacje</NavLink></li>
                      <li><NavLink onClick={() => this.handleNav('kontakt')} isActive={this.handleActiveLink} to="/kontakt">kontakt</NavLink></li>
                      <li><NavLink onClick={() => this.handleNav('polityka prywatności')} isActive={this.handleActiveLink} to="/polityka_prywatnosci">polityka prywatności</NavLink></li>
                    </ul>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        <Switch>
          <Route path="/" exact component={Index} />
          <Route path="/o_nas" component={About} />
          <Route path="/dotacje_1" component={Grants} />
          <Route path="/pompy_ciepla" component={HeatPumps} />
          <Route path="/zrodla_energii" component={EnergySources} />
          <Route path="/instalacje" component={Fittings} />
          <Route path="/realizacje" component={Realizations} />
          <Route path="/zmodernizuj_swoja_kotlownie" component={UpgradeOilBoilerRoom} />
          <Route path="/kontakt" component={Contact} />
          <Route path="/polityka_prywatnosci" component={PrivacyPolicyPage} />
          <Route path='*' component={NotFoundPage} />/
        </Switch>
      </>
    );
  }
}

export default TopBar;