import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import "./HomeGallery.scss";

import kotly_gaz_olej1 from "./assets/kotly_gaz_olej1.jpg";
import fotowoltaika_s2 from "./assets/fotowoltaika_s2.jpg";
import biomasa_s1 from "./assets/biomasa_s1.jpg";
import podlogowe_s1 from "./assets/podlogowe_s1.jpg";
import rekuperacja_s from "./assets/rekuperacja_s.jpg";
import kolektory_s from "./assets/kolektory_s.jpg";
import kolty_gaz_olej2 from "./assets/kolty_gaz_olej2.jpg";
import news_03 from "./assets/news_03.jpg";

export default class HomeGallery extends Component {
    render() {
        return (
            <div className='HomeGallery'>
                <div className='HomeGallery-row'>
                    <div>
                        <NavLink className='' to='/pompy_ciepla'>
                            <img src={kotly_gaz_olej1} alt="kotly, gaz, olej" />
                            <h3>pompy ciepła
                                <span>oszczędzność</span>
                            </h3> 
                        </NavLink>
                    </div>
                    <div>
                        <NavLink className='' to='/zrodla_energii/fotowoltaika'>
                            <img src={fotowoltaika_s2} alt="fotowoltaika" />
                            <h3>fotowoltaika
                                <span>energia słoneczna</span>
                            </h3>
                        </NavLink>    
                    </div>
                    <div>
                        <NavLink className='' to='/zrodla_energii/kotly_na_biomase'>
                            <img src={biomasa_s1} alt="kotły na biomasę" />
                            <h3>kotły
                                <span>na biomasę</span>
                            </h3>
                        </NavLink>
                    </div>
                    <div>
                        <NavLink className='' to='/instalacje/ogrzewanie_podlogowe'>
                            <img src={podlogowe_s1} alt="ogrzewanie podłogowe" />
                            <h3>ogrzewanie
                                <span>podłogowe</span>
                            </h3>
                        </NavLink>
                    </div>
                </div>
                <div className='HomeGallery-row'>
                    <div>
                        <NavLink className='' to='/zrodla_energii/rekuperacja'>
                            <img src={rekuperacja_s} alt="rekuperacja" />
                            <h3>rekuperacja
                                <span>odzysk ciepła</span>
                            </h3>
                            
                        </NavLink>
                    </div>
                    <div>
                        <NavLink className='' to='/zrodla_energii/kolektory_sloneczne'>
                            <img src={kolektory_s} alt="kolektory słoneczne" />
                            <h3>kolektory
                                <span>słoneczne</span>
                            </h3>
                        </NavLink>
                    </div>
                    <div>
                        <NavLink className='' to='/zrodla_energii/kotly_gazowe_olejowe'>
                            <img src={kolty_gaz_olej2} alt="kotły gazowe/olejowe" />
                            <h3>kotły
                                <span>gaz/olej</span>
                            </h3>
                        </NavLink>
                    </div>
                    <div>
                        <NavLink className='' to='/realizacje'>
                            <img src={news_03} alt="realizacje" />
                            <h3>realizacje
                                <span>wykonane prace</span>
                            </h3>
                        </NavLink>
                    </div>
                </div>
            </div>
        );
    }
}