import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import logoFooter from '../assets/logo_footer.png';
import "./BottomBar.scss";

export default class BottomBar extends Component {
    render() {
        return (
            <div className='BottomBar' >
                <div className='BottomBar-container'>
                    <div className='BottomBar-container-contact'>
                        <h2>+48 182669226</h2>
                        <h4>biuro@pompyciepla-podhale.com.pl</h4>
                    </div>
                    <div className='BottomBar-container-logo'>
                        <div className='BottomBar-container-logo-img'>
                            <a href="/">
                                <img src={logoFooter} alt='Logo' />
                            </a>
                        </div>
                        <div className='BottomBar-container-logo-txt'>
                            <p className='privacy'>Copyright Pompy ciepła Podhale
                                <span>&#169;</span>
                                <span>2023</span>
                                <span>| wykonanie: lusia</span>
                            </p>
                            <span className='rodo'>
                                <NavLink to="/polityka_prywatnosci">polityka prywatności</NavLink>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}