import React, { Component } from 'react';

import nibeSplit from "../../assets/nibeSplit.jpg";
import nibe_2 from "../../assets/nibe_2.jpg";

import "./HeatPumpsPageAir.scss";

export default class HeatPumpsPageAir extends Component {
    render () {
        return (
            <div className="HeatPumpsPageAir">
                <h4>pompy powietrzne</h4>
                <p>
                    <strong>Pompy ciepła powietrzne</strong> możemy podzielić na parę podgrup, które do pracy wykorzystują powietrze jako dolne źródło.
                </p>
                <div>
                    <div className="HeatPumpsPageAir-wrapper">
                        <p>
                            I tak często spotykane, ze względu na ceny, to pompy typu SPLIT, czyli pompy w których jednostkę zewnętrzną, z jednostką wewnętrzną, łączymy linią chłodniczą, czyli przewodem cieczowym i gazowym. Pompy tego typu, wymagają od instalatora specjalistycznej wiedzy chłodniczej, oraz wielkiej staranności przy wykonywaniu połączenia chłodniczego. Jednostki zewnętrzne tych pomp, są najczęściej zmodyfikowanymi jednostkami klimatyzacyjnymi.
                            Bardzo często, spotykaną pompą powietrzną, jest pompa typu monoblok, czyli pompa do której doprowadzamy czynnik grzewczy płynący w instalacji domowej, czyli wodę. W przypadku takich pomp, instalator musi zadbać o prawidłowe zaizolowanie przewodów zasilania i powrotu.
                        </p>
                        <img src={nibeSplit} alt='pompy powietrzne'></img>
                    </div>
                    <p>
                    Rzadko spotykaną na rynku polski, ale występującą w ofercie paru firm, jest pompa ciepła powietrze – powietrze, która jest używana w krajach gdzie budujemy domy w technologii szkieletu drewnianego. Może też być ciekawą alternatywą dla domów wykorzystywanych okresowo, bo po włączeniu po kilkunastu minutach pomieszczenia są ciepłe.
                    Możemy przyjąć, że w chwili obecnej, do ogrzewania domów mieszkalnych stosowane będą głównie pompy powietrzne, ze względu na zdecydowanie niższe koszty inwestycji, w stosunku do pomp gruntowych. Różnica w kosztach inwestycji pomiędzy pompami gruntowymi, a powietrznymi, i kosztami eksploatacji, wyrównuje się w zależności od modelu pompy po minimum 18 latach a w niektórych przypadkach, po jeszcze dłuższym okresie. Ponieważ przyjmujemy średnio żywotność pompy ciepła na 22 – 25 lat, to inwestycja w pompę gruntową jest obecnie nie do końca uzasadnioną. Na rynku Szwedzkim pompy powietrzne, stanowią 72% sprzedaży a na rynku Niemieckim, około 80%.
                    </p>
                </div>
                <img src={nibe_2} alt='pompy powietrzne'></img>

            </div>
        );
    }
} 