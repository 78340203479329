import React, { Component, Fragment } from 'react';

import nibe from './assets/nibe.pdf';
import Page from '../Page/Page';
import './AboutUsPage.scss';

export default class AboutUsPage extends Component {
    leftPanel() {
        return (
            <Fragment>
                <h4>O nas</h4>
                <p>Firma nasza działa od ponad 25 lat w branży instalacyjnej na terenie Podhala, Małopolski jak i całego kraju.</p>
                <p>Dysponując doświadczoną kadrą wykonawczą i inżynieryjną zrealizowaliśmy w tym czasie szereg inwestycji – od niewielkich przydomowych kotłowni do ponad  megawatowych bloków cieplnych.</p>
                <p>Zawsze podążaliśmy za najnowszymi i rozsądnymi dla inwestora rozwiązaniami techniki grzewczej. Obecnie uważamy, że  pompy ciepła są naturalnym i właściwym kierunkiem w poszukiwaniu oszczędnych i ekologicznych żródeł ciepła.
                Korzystając z naszych doświadczeń pragniemy państwu zaproponować profesjonalny:
                </p>
                <ul className='Page-list'>
                    <li>Montaż</li>
                    <li>Doradztwo</li>
                    <li>Wycenę</li>
                    <li>Analizę i optymalizację kosztów</li>
                </ul>
                <p>W zakresie:</p>
                <ul className='Page-list'>
                    <li>Ogrzewania płaszczyznowego</li>
                    <li>Klimakonwektorów</li>
                    <li>Techniki solarnej i fotowoltaiki</li>
                </ul>
                <h6>Zaufaj doświadczeniu!</h6>
            </Fragment>
        );
    }

    rightPanel() {
        return (
            <Fragment>
                <h4>o naszej stronie</h4>
                <p>
                Strona na której mamy przyjemność państwa gościć powstała w celu popularyzacji tematyki związanej z pompami ciepła jako źródłem ekologicznego i oszczędnego ciepła.</p>
                <p>Chcemy również poprzez naszą witrynę propagować właściwe rozwiązania i rozwiać pewne „mity i fakty” związane z pompami ciepła. Dlatego też informacje zawarte na niej oparte są również na naszym doświadczeniu zdobytym w tej dziedzinie.</p>
                <p>Zapraszamy również do aktywnego udziału w dyskusji na temat pomp ciepła poprzez nasz powstający blog.</p>
                <p>Zapraszamy do kontaktu z nami i skorzystania z naszych usług!</p>

                <a href={nibe} target='_blank' rel="noopener noreferrer">
                    Certyfikat nibe
                </a>
            </Fragment>
        );
    }

    render() {
        return (
            <div className='AboutUsPage'>
                <Page
                    childLeftPanel={this.leftPanel()}
                    childRightPanel={this.rightPanel()}
                />
                <div className='AboutUsPage-panel-bottom'>
                    <h4>Ponadto oferujemy usługi w zakresie</h4>
                    <ul className='AboutUsPage-list'>
                        <li>kotłowni gazowych, olejowych, na biomasę</li>
                        <li>instalacji ogrzewania podłogowego, ściennego oraz sufitowego</li>
                        <li>instalacji wodno-kanalizacyjnych</li>
                        <li>instalacji gazowych</li>
                        <li>instalacji hydrantowych i tryskaczowych</li>
                        <li>systemów klimatyzacyjnych split, multi-split, VRF</li>
                        <li>odkurzaczy centralnych</li>
                    </ul>
                </div>
            </div>
        );
    }
}