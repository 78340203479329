import React, { Component, Fragment } from 'react';
import { Route, Switch, NavLink } from "react-router-dom";

import Page from "../Page/Page";
import './GrantsPage.scss';

import ClearAirProgramPage from "./components/ClearAirProgramPage";
import SzwedzkaDotacja from "./components/SzwedzkaDotacja";
import NotFoundPage from "../PageNotFound/PageNotFound";

export default class GrantsPage extends Component {
    handleActiveLink = (match, location) => {
        if (!match) {
            return false;
        }
        return true;
    }

    mainPage() {
        return (
            <Fragment>
                <h4>Dotacje</h4>
                <p>
                    Pomagamy pisać wnioski naszym Klientom dla potrzeb programu <u>"Czyste powietrze"</u>
                </p>
            </Fragment>
        );
    }

    rightPanel() {
        return (
            <Switch>
                <Route exact path="/dotacje_1" component={this.mainPage} />
                <Route exact path="/dotacje_1/program_czyste_powietrze" component={ClearAirProgramPage} />
                <Route exact path="/dotacje_1/szwedzka_dotacja" component={SzwedzkaDotacja} />
                <Route exact path='*' component={NotFoundPage} />
            </Switch>
        );
    }

    leftPanel() {
        return (
            <Fragment>
                <h4>menu</h4>
                <ul className='GrantsPage-links Page-list'>
                    <li>
                        <NavLink to="/dotacje_1/program_czyste_powietrze" isActive={this.handleActiveLink}>program czyste powietrze</NavLink>
                    </li>
                    <li>
                        <NavLink to="/dotacje_1/szwedzka_dotacja" isActive={this.handleActiveLink}>szwedzka promocja</NavLink>
                    </li>
                </ul>
            </Fragment>
        );
    }

    render() {
        return (
            <div className='GrantsPage'>
                <Page
                    className='GrantsPage-Page'
                    childLeftPanel={this.leftPanel()}
                    childRightPanel={this.rightPanel()}
                />
            </div>
        );
    }
}

