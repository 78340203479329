import React, { Component } from 'react';

import czystePowietrze from "../../assets/czystePowietrze.jpg";
import ulotkaCzystePowietrze from "../../assets/ulotkaCzystePowietrze.pdf";
import "./ClearAirProgramPage.scss";

export default class ClearAirProgramPage extends Component {
    render() {
        return (
            <div className="ClearAirProgramPage">
                <h4>program czyste powietrze</h4>
                <p>Rządowy program dotacji „CZYSTE POWIETRZE”</p>
                <a href={ulotkaCzystePowietrze} target='_blank' rel="noopener noreferrer">
                    <img src={czystePowietrze} alt='czystePowietrze'></img>
                </a>
                <h3>o programie</h3>
                <p>
                    Czyste Powietrze to rządowy ogólnopolski program wsparcia finansowego inwestycji polegających na montażu nowego źródła ciepła w budynkach nowobudowanych
                    lub termomodernizacji i wymianie źródła ciepła w budynkach istniejących. Celem programujest zmniejszenie lub uniknięcie emisji pyłów i innych zanieczyszczeń
                    wprowadzanych do atmosfery.
                </p>
                <h3>dla kogo?</h3>
                <p>
                    Dla właścicieli lub współwłaścicieli jednorodzinnych budynków mieszkalnych, lub
                    wydzielonych w budynkach jednorodzinnych lokali mieszkalnych z wyodrębnioną
                    księgą wieczystą
                </p>
                <h3>Zakres wsparcia</h3>
                <p>
                    Dofinansowanie wymiany starych i nieefektywnych źródeł ciepła na paliwo stałe na
                    nowoczesne źródła ciepła spełniające najwyższe normy, oraz przeprowadzenia
                    niezbędnych prac termomodernizacyjnych budynku
                </p>
                <h3>Kwota dofinansowania</h3>
                <p>
                    Dotacja może wynosić do 30 000 zł dla podstawowego poziomu dofinansowania i 37
                    000 zł dla podwyższonego poziomu dofinansowania
                </p>
                <h3>cel programu</h3>
                <p>
                    Poprawa jakości powietrza oraz zmniejszenie emisji gazów cieplarnianych poprzez
                    wymianę źródeł ciepła i poprawę efektywności energetycznej budynków
                    mieszkalnych jednorodzinnych.
                </p>
                <p>
                    Narzędziem w osiągnięciu celu jest dofinansowanie przedsięwzięć realizowanych
                    przez beneficjentów uprawnionych do podstawowego poziomu dofinansowania oraz
                    beneficjentów uprawnionych do podwyższonego poziomu dofinansowania.
                </p>
                <h4>
                    Część pierwsza programu dla Beneficjentów
                    uprawnionych do podstawowego poziomu
                    dofinansowania
                </h4>
                <h3>Formy dofinansowania</h3>
                <ul className='Page-list'>
                    <li>
                        dotacja
                    </li>
                    <li>
                        dotacja z przeznaczeniem na częściową spłatę kapitału kredytu
                        bankowego <span className='highlight'>(uruchomienie w późniejszym terminie)</span>
                    </li>
                </ul>
                <h3>Rodzaje wspieranych przedsięwzięć wraz z maksymalnymi kwotami dofinansowania</h3>
                <strong>Opcja 1</strong>
                <p>
                    Przedsięwzięcie obejmujące demontaż nieefektywnego źródła ciepła na paliwo stałe
                    oraz zakup i montaż pompy ciepła typu powietrze-woda albo gruntowej pompy ciepła
                    do celów ogrzewania lub ogrzewania i cwu.
                </p>
                <p>
                    Dodatkowo mogą być wykonane (dopuszcza się wybór więcej niż jednego elementu
                    z zakresu):
                </p>
                <ul className='Page-list'>
                    <li>
                        demontaż oraz zakup i montaż nowej instalacji centralnego ogrzewania lub cwu (w
                        tym kolektorów słonecznych),
                    </li>
                    <li>
                        zakup i montaż mikroinstalacji fotowoltaicznej,
                    </li>
                    <li>
                        zakup i montaż wentylacji mechanicznej z odzyskiem ciepła,
                    </li>
                    <li>
                        zakup i montaż ocieplenia przegród budowlanych, okien, drzwi zewnętrznych,
                        drzwi/bram garażowych (zawiera również demontaż),
                    </li>
                    <li>
                        dokumentacja dotycząca powyższego zakresu: audyt energetyczny (pod
                        warunkiem wykonania ocieplenia przegród budowlanych), dokumentacja projektowa,
                        ekspertyzy.
                    </li>
                </ul>
                <h3>Kwota maksymalnej dotacji:</h3>
                <ul className='Page-list'>
                    <li>
                        25 000 zł – gdy przedsięwzięcie nie obejmuje mikroinstalacji fotowoltaicznej
                    </li>
                    <li>
                        30 000 zł – dla przedsięwzięcia z mikroinstalacją fotowoltaiczną
                    </li>
                </ul>
                <strong>Opcja 2</strong>
                <p>
                    Przedsięwzięcie obejmujące demontaż nieefektywnego źródła ciepła na paliwo stałe
                    oraz:
                </p>
                <ul>
                    <li>
                        zakup i montaż innego źródła ciepła niż wymienione w opcji 1 (powyżej) do celów
                        ogrzewania lub ogrzewania i cwu albo
                    </li>
                    <li>
                        zakup i montaż kotłowni gazowej w rozumieniu Załącznika 2 do Programu.
                    </li>
                </ul>
                <p>
                    Dodatkowo mogą być wykonane (dopuszcza się wybór więcej niż jednego elementu
                    z zakresu):
                </p>
                <ul className='Page-list'>
                    <li>
                        demontaż oraz zakup i montaż nowej instalacji centralnego ogrzewania lub cwu (w
                        tym kolektorów słonecznych, pompy ciepła wyłącznie do cwu)
                    </li>
                    <li>
                        zakup i montaż mikroinstalacji fotowoltaicznej,
                    </li>
                    <li>
                        zakup i montaż wentylacji mechanicznej z odzyskiem ciepła,
                    </li>
                    <li>
                        zakup i montaż ocieplenia przegród budowlanych, okien, drzwi zewnętrznych,
                        drzwi/bram garażowych (zawiera również demontaż),
                    </li>
                    <li>
                        dokumentacja dotycząca powyższego zakresu: audyt energetyczny (pod
                        warunkiem wykonania ocieplenia przegród budowlanych), dokumentacja projektowa,
                        ekspertyzy
                    </li>
                </ul>
                <h3>Kwota maksymalnej dotacji:</h3>
                <ul className='Page-list'>
                    <li>
                        20 000 zł – gdy przedsięwzięcie nie obejmuje mikroinstalacji fotowoltaicznej
                    </li>
                    <li>
                        25 000 zł – dla przedsięwzięcia z mikroinstalacją fotowoltaiczną
                    </li>
                </ul>
                <strong>Opcja3</strong>
                <p>
                    Przedsięwzięcie nie obejmujące wymiany źródła ciepła na paliwo stałe na nowe
                    źródło ciepła, a obejmujące (dopuszcza się wybór więcej niż jednego elementu z
                    zakresu):
                </p>
                <ul className='Page-list'>
                    <li>
                        zakup i montaż wentylacji mechanicznej z odzyskiem ciepła,
                    </li>
                    <li>
                        zakup i montaż ocieplenia przegród budowlanych, okien, drzwi zewnętrznych,
                        drzwi/bram garażowych (zawiera również demontaż),
                    </li>
                    <li>
                        wykonanie dokumentacji dotyczącej powyższego zakresu: audytu energetycznego
                        (pod warunkiem wykonania ocieplenia przegród budowlanych), dokumentacji
                        projektowej, ekspertyz.
                    </li>
                </ul>
                <h3>Kwota maksymalnej dotacji:</h3>
                <p>10 000 zł</p>
                <h3>Beneficjenci</h3>
                <p>
                    Beneficjenci to osoby fizyczne, będące właścicielami/współwłaścicielami budynków
                    mieszkalnych jednorodzinnych lub wydzielonych w budynkach jednorodzinnych lokali
                    mieszkalnych z wyodrębnioną księgą wieczystą, o dochodzie rocznym
                    nieprzekraczającym kwoty 100 000 zł,
                </p>
                <p>
                    W przypadku uzyskiwania dochodów z różnych źródeł, dochody sumuje się, przy
                    czym suma ta nie może przekroczyć kwoty 100 000 zł.
                </p>
                <h4>Część druga programu dla Beneficjentów uprawnionych do podwyższonego poziomu dofinansowania</h4>
                <h3>Formy dofinansowania</h3>
                <ul className='Page-list'>
                    <li>dotacja</li>
                    <li>
                        pożyczka dla gmin, jako uzupełniające finansowanie dla Beneficjentów <span className="highlight">(uruchomienie w późniejszym terminie)</span>
                    </li>
                    <li>
                        dotacja z przeznaczeniem na częściową spłatę kapitału kredytu
                        bankowego <span className="highlight">(uruchomienie w późniejszym terminie)</span>
                    </li>
                </ul>
                <h3>Rodzaje wspieranych przedsięwzięć wraz z maksymalnymi kwotami dofinansowania</h3>
                <strong>Opcja 1</strong>
                <p>
                    Przedsięwzięcie obejmujące demontaż nieefektywnego źródła ciepła na paliwo stałe
                    oraz:
                </p>
                <ul className='Page-list'>
                    <li>
                        zakup i montaż źródła ciepła do celów ogrzewania lub ogrzewania i cwu albo
                    </li>
                    <li>
                        zakup i montaż kotłowni gazowej w rozumieniu Załącznika 2a do Programu.
                    </li>
                </ul>
                <p>
                    Dodatkowo mogą być wykonane (dopuszcza się wybór więcej niż jednego elementu
                    z zakresu):
                </p>
                <ul className='Page-list'>
                    <li>
                        demontaż oraz zakup i montaż nowej instalacji centralnego ogrzewania lub cwu (w
                        tym kolektorów słonecznych, pompy ciepła wyłącznie do cwu),
                    </li>
                    <li>
                        zakup i montaż mikroinstalacji fotowoltaicznej,
                    </li>
                    <li>
                        zakup i montaż wentylacji mechanicznej z odzyskiem ciepła,
                    </li>
                    <li>
                        zakup i montaż ocieplenia przegród budowlanych, okien, drzwi zewnętrznych,
                        drzwi/bram garażowych (zawiera również demontaż),
                    </li>
                    <li>
                        dokumentacja dotycząca powyższego zakresu: audyt energetyczny (pod
                        warunkiem wykonania ocieplenia przegród budowlanych), dokumentacja projektowa,
                        ekspertyzy.
                    </li>
                </ul>
                <h3>Kwota maksymalnej dotacji:</h3>
                <ul className='Page-list'>
                    <li>
                        32 000 zł – gdy przedsięwzięcie nie obejmuje mikroinstalacji fotowoltaicznej
                    </li>
                    <li>
                        37 000 zł – dla przedsięwzięcia z mikroinstalacją fotowoltaiczną
                    </li>
                </ul>
                <strong>Opcja 2</strong>
                <p>
                    Przedsięwzięcie nie obejmujące wymiany źródła ciepła na paliwo stałe na nowe
                    źródło ciepła, a obejmujące (dopuszcza się wybór więcej niż jednego elementu z
                    zakresu):
                </p>
                <ul className='Page-list'>
                    <li>
                        zakup i montaż wentylacji mechanicznej z odzyskiem ciepła,
                    </li>
                    <li>
                        zakup i montaż ocieplenia przegród budowlanych, okien, drzwi zewnętrznych,
                        drzwi/bram garażowych (zawiera również demontaż),
                    </li>
                    <li>
                        wykonanie dokumentacji dotyczącej powyższego zakresu: audytu energetycznego
                        (pod warunkiem wykonania ocieplenia przegród budowlanych), dokumentacji
                        projektowej, ekspertyz.
                    </li>
                </ul>
                <h3>Kwota maksymalnej dotacji:</h3>
                <p>15 000 zł</p>
                <h3>Beneficjenci</h3>
                <ul className='Page-list'>
                    <li>Beneficjenci</li>
                    <ul className='Page-list'>
                        <li>
                            są właścicielami/współwłaścicielami budynku mieszkalnego jednorodzinnego lub
                            wydzielonego w budynku jednorodzinnym lokalu mieszkalnego z wyodrębnioną
                            księgą wieczystą ;
                            </li>
                        <li>
                            przeciętny miesięczny dochód na jednego członka ich gospodarstwa domowego nie przekracza kwoty:
                                <ul className='Page-list'>
                                <li>
                                    1400 zł w gospodarstwie wieloosobowym,
                                    </li>
                                <li>
                                    1960 zł w gospodarstwie jednoosobowym.
                                    </li>
                            </ul>
                        </li>
                    </ul>
                    <li>
                        W przypadku prowadzenia działalności gospodarczej, roczny przychód
                        beneficjenta z tytułu prowadzenia pozarolniczej działalności gospodarczej za rok
                        kalendarzowy, za który ustalony został przeciętny miesięczny dochód, nie
                        przekroczył trzydziestokrotności kwoty minimalnego wynagrodzenia za pracę
                        określonego w rozporządzeniu Rady Ministrów obowiązującym w grudniu roku
                        poprzedzającego rok złożenia wniosku o dofinansowanie.
                    </li>
                </ul>
            </div>
        );
    }
}