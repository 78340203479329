import React, { Component } from 'react';

export default class Regeneration extends Component {
    render () {
        return (
            <div className="Regeneration">
                <h4>rekuperacja</h4>
                <p>Tekst w przygotowaniu.</p>                
            </div>
        );
    }
} 