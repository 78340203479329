import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import "./RealizationToDisplay.scss";

export default class RealizationToDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      imgIndex: 0
    };

    this.handleLightBoxOpen = this.handleLightBoxOpen.bind(this);
  }

  handleLightBoxOpen(index) {
    this.setState({ isOpen: true, imgIndex: index });
  }

  renderarticleBody(imgDesc, thumbnailUrl, i) {
    const { isOpen, imgIndex } = this.state;
    const { realization } = this.props;
    let titelImg = null;

    const imglUrls = realization.bodyContent.map(
      bodyContent => `/assets/realizacje/${bodyContent.imgUrl}`
    );
    const mainSrc = imglUrls[imgIndex];

    if (isOpen) {
      titelImg = realization.bodyContent[imgIndex].imgDesc;
    }


    return (
      <div className="RealizationToDisplay-row-content" key={i}>
        <span className='RealizationToDisplay-row-content-img-wrapper' onClick={() => this.handleLightBoxOpen(i)}>
          <img src={`/assets/realizacje/thumbs/${thumbnailUrl}`} alt={imgDesc} />
          <span className="gallery_icon"><i className="fa fa-expand"></i></span>
        </span>
        <p>{imgDesc}</p>
        {isOpen && (
          <Lightbox
            mainSrc={mainSrc}
            nextSrc={imglUrls[(imgIndex + 1) % imglUrls.length]}
            imageTitle={titelImg}
            prevSrc={
              imglUrls[
              (imgIndex + imglUrls.length - 1) % imglUrls.length
              ]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                imgIndex:
                  (imgIndex + imglUrls.length - 1) %
                  imglUrls.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                imgIndex: (imgIndex + 1) % imglUrls.length
              })
            }
          />
        )}
      </div>
    );
  }

  render() {
    const { realization } = this.props;

    if (Object.keys(realization).length === 0) {
      return null;
    }

    const { title, artBody, bodyContent } = realization;

    return (
      <div className="RealizationToDisplay">
        <h4>{title}</h4>
        <p>{artBody}</p>
        <div className="RealizationToDisplay-row">
          {bodyContent.map(({ imgDesc, thumbnailUrl }, i) =>
            this.renderarticleBody(imgDesc, thumbnailUrl, i)
          )}
        </div>
      </div>
    );
  }
}
